
@import url("styleguide.scss");
@import url("customModal.scss");

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}


web-app {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;


}

.ngx-pagination {
	text-align: center;
	a {
		color: #0d1f2d !important;
	}
	.current {
		background: #7850FA !important;
		border-radius: 8px;
	}
}

.appLoader {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #f9f8fa;
	z-index: 9;
	display: none;
}

.appLoader p {
	margin-top: 32px;
	margin-bottom: 4px;
	font-size: 16px;
	letter-spacing: 0px;
	text-align: center;
	color: rgba(255, 255, 255, 0.7);
	line-height: 20px
}

.appLoader h3 {
	height: 31px;
	font-family: Dosis;
	font-size: 24px;
	font-weight: 600;
	letter-spacing: 2px;
	text-align: center;
	color: #ffffff;
	padding: 0;
	margin-top: 15px;
	opacity: 0.3;
	transition: all 3s;
	transition-delay: 0.2s;
}

.appLoader h3.animate {
	margin-top: 0px !important;
	opacity: 1.0 !important;
	transition: all 3s;
	transition-delay: 0.2s;
}

.appLoader aside {
	position: absolute;
	top: calc(50% - 123px);
	left: calc(50% - 123px);
	height: 246px;
	width: 246px;
	background: #7f6ca6;
	border-radius: 50%;
	border-top: 3px solid #00b898;
	animation: rotate 1s linear 0s infinite;
}

@keyframes rotate {
	from {
		transform: rotate(0deg)
	}

	to {
		transform: rotate(360deg)
	}
}

.appLoader img:nth-last-child(2) {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-85px, -110px);
}

.appLoader img:last-child {
	position: absolute;
	bottom: 40px;
	left: 50%;
	transform: translate(-50%, 0);
}

.appLoader.active {
	display: block;
}

.loaderBox:before,
.loaderBox:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	height: 50px;
	width: 50px;
	border-radius: 100%;
	transform: translate(-50%, -50%);
	border: 2px solid transparent;
	border-top-color: #601fe0;
}

.loaderBox:before {
	z-index: 100;
	margin-top: -25px;
	margin-left: -25px;
	animation: spin 1s infinite;
}

.loaderBox:after {
	border: 2px solid #d8d8d8;
}

.loaderBox {
	.logo-inside {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 50px;
		width: 50px;
		border-radius: 100%;
		transform: translate(-50%, -50%);
		background-image: url('../../log.svg');
		background-repeat: no-repeat;
		background-position: center;

	}
}

/* SITE HEADER */

app-header {








}

app-header.nav-collapsed {
	left: 72px;
	padding: 0 0 0 24px;
}

/* SIDEBAR CSS */

app-sidebar {
	display: inline-block;



	min-height: 100vh;

	a:hover {
		border: none;
		text-decoration: none;
	}

}

app-sidebar.nav-collapsed {
	width: 72px;
}

svg {
	vertical-align: baseline !important;
}

.app-sidebar {
	height: 100vh;
	overflow: hidden;
	position: relative;
	transition: all 0.3s;
	background: #ededed;
}

.sidebar-client-info {
	width: 100%;
	height: 56px;

	padding-top: 16px;
	padding-bottom: 31px;
	position: relative;
	padding-left: 25px;

}

.sidebar-client-info>object:nth-child(2) {
	margin-left: 9px;
}

.sidebar-client-info h4 {
	height: 20px;
	font-size: 20px;
	line-height: 1;
	letter-spacing: 1.5px;
	transform: scale(1.0);
	transform-origin: left;
	transition: all 0.3s;
	position: relative;
	left: 14px;
	float: left;
	overflow: hidden;
	background: -webkit-linear-gradient(7deg, #3023ae, #c86dd7);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.sidebar-client-info p {
	height: 30px;
	display: none;
	color: #546a7b;
	margin: -5px 7px 0 7px;
	text-align: center;
	float: left;
}

.sidebar-client-info svg {
	position: absolute;
	left: 180px;
	top: 16px;
	cursor: pointer;
	transition: all 0.3s;
	color: grey;
}

.sidebar-navigation {
	padding: 16px 0;
	overflow-y: hidden;
	height: calc(100vh - 56px);
}

.sidebar-navigation:hover {
	overflow-y: auto;
}

.sidebar-navigation::-webkit-scrollbar,
.app-content section.site-content .main-content::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	transform: translate(5px, 0);
}

.sidebar-navigation::-webkit-scrollbar-track,
.app-content section.site-content .main-content::-webkit-scrollbar-track {
	background: none;
	border-radius: 20px;
}

.sidebar-navigation::-webkit-scrollbar-thumb,
.app-content section.site-content .main-content::-webkit-scrollbar-thumb {
	background: none;
	border-radius: 20px;
}

.app-content section.site-content .main-content::-webkit-scrollbar-thumb {
	background: none;
}

.sidebar-navigation>span {
	font-size: 11px;
	line-height: 13px;
	display: inline-block;
	letter-spacing: 1px;
	padding: 6px 24px;
	text-align: left;
	transition: all 0.3s;
}

.sidebar-navigation .outer-list>hr {
	border-top: 0.1px solid #e5e5e5;
	margin: 0px 25px 0px 25px;
}

.sidebar-navigation .outer-list>li>a {
	box-sizing: border-box;
	padding: 6px 24px;
	line-height: 24px;
	font-size: 14px;
	height: 36px;
	display: block;
	letter-spacing: 0.2px;
	color: #23445c;
	text-transform: capitalize;
	position: relative;
	cursor: pointer;
	transition: all 0.3s;
	z-index: 104;
	font-weight: 500 !important;
}

.sidebar-navigation .outer-list>li>a label {
	position: relative;
	/*padding-left:*/
}

.sidebar-navigation .outer-list>li>a svg>path {
	fill: #546a7b;
}

.sidebar-navigation .outer-list>li>a:hover {
	background: inherit;
	/* color: #7850FA; */
}

.sidebar-navigation .outer-list>li.active>a,
.sidebar-navigation .outer-list>li.active:hover>a {
	color: #601fe0;
	background-color: none;
}

.sidebar-navigation .outer-list>li.check:not(.active)>a,
.sidebar-navigation .outer-list>li.check:not(.active):hover>a {
	/* color: #7850FA; */
	background: none;
}

.sidebar-navigation .outer-list>li.has-child-nav>a:after {
	content: '';
	height: 7px;
	width: 7px;
	display: block;
	border-left: 2px solid rgba(255, 255, 255, 0.35);
	border-bottom: 2px solid rgba(255, 255, 255, 0.35);
	position: absolute;
	top: 21px;
	left: 210px;
	transform: rotate(-135deg);
	transition: all 0s;
}

.sidebar-navigation .outer-list>li.active.has-child-nav>a:after,
.sidebar-navigation .outer-list>li.check.has-child-nav>a:after {
	border-color: rgba(255, 255, 255, 1);
	top: 20px;
	transform: rotate(-45deg);
}

.sidebar-navigation .outer-list>li.active>a svg path,
.sidebar-navigation .outer-list>li.check>a svg path {
	fill: #7850FA;
}

.sidebar-navigation .outer-list>li>a>span {
	margin-left: 12px;
	transform: translate(0, -7px);
	display: inline-block;
	letter-spacing: 0.3;
	/* color: #7850FA; */
}

.sidebar-navigation .outer-list>li .inner-list {
	max-height: 0;
	width: 100%;
	overflow: hidden;
	transition: all 0.3s;
	transition-delay: 0s;
	position: relative;
	z-index: 103;
}

.sidebar-navigation .outer-list>li .inner-list>li>a {
	color: #23445c;
	line-height: 18px;
	font-size: 13px;
	text-transform: capitalize;
	display: block;
	padding: 0px 22px 7px 60px;
	letter-spacing: 0.2px;
	background: inherit;
	/* border-left: 5px solid #463761; */
	font-weight: 500 !important;
}

.sidebar-navigation .outer-list>li .inner-list>li.active>a {
	background: none;
	color: #601fe0;
	font-weight: normal;
	/* border-left-color: #4b338d; */
}

.sidebar-navigation .outer-list>li .inner-list>li:hover>a {
	/* color: #7850FA; */
	background: none;
}

.sidebar-navigation .outer-list>li.active .inner-list,
.sidebar-navigation .outer-list>li.check .inner-list {
	max-height: 400px;
	overflow: hidden;
	transition-delay: 0s;
}

.sidebar-brand-logo {
	display: block;
	vertical-align: bottom;
}

.sidebar-brand-logo a {
	display: block;
	text-align: center;
	color: #7850FA;
	line-height: 24px;
	font-size: 14px;
	letter-spacing: 1px;
}

.app-sidebar.collapsed {
	width: 72px;
	overflow: visible;
}

.app-sidebar.collapsed .sidebar-client-info h4 {
	height: 0;
	width: 0;
}

.app-sidebar.collapsed .sidebar-client-info p {
	display: block;
}

.app-sidebar.collapsed .sidebar-client-info svg {
	height: 16px;
	width: 16px;
	left: 56px;
	bottom: 0;
	top: 40px;
}

.app-sidebar.collapsed .sidebar-navigation {
	overflow: visible;
}

.app-sidebar.collapsed .sidebar-navigation>span {
	font-size: 11px;
	line-height: 13px;
	display: block;
	letter-spacing: 1px;
	padding: 6px 0;
	text-align: center;
}

.app-sidebar.collapsed .sidebar-navigation .outer-list>li {
	position: relative;
}

.app-sidebar.collapsed .sidebar-navigation .outer-list>li>a {
	width: 72px;
	display: inline-flex;
	padding: 12px 23px 12px 24px;
}

.app-sidebar.collapsed .sidebar-navigation .outer-list>li>a>svg {
	display: flex;
}

.app-sidebar.collapsed .sidebar-navigation .outer-list>li>a>span {
	width: 1px;
	padding-left: 1px;
	margin-left: 0px;
	overflow: hidden;
	display: flex;

}

.app-sidebar.collapsed .sidebar-navigation .outer-list>li.has-child-nav>a:after {
	display: none;
}

.app-sidebar.collapsed .sidebar-navigation .outer-list>li:hover>a {
	width: 264px;
	border-radius: 0 3px 3px 0;
	background: #2b223c;
	color: rgba(255, 255, 255, 0.8);
}

.app-sidebar.collapsed .sidebar-navigation .outer-list>li.active>a {
	background: #4e3970;
}

.app-sidebar.collapsed .sidebar-navigation .outer-list>li.active:hover>a>label:after,
.app-sidebar.collapsed .sidebar-navigation .outer-list>li.active>a>label:after {
	content: "";
	position: absolute;
	left: -24px;
	top: -12px;
	height: 48px;
	width: 72px;
	display: inline-block;
	z-index: -1;
	background: #08a38d;
}

.app-sidebar.collapsed .sidebar-navigation .outer-list>li:hover>a>span {
	padding-left: 47px;
	margin-left: 0;
	transform: translate(0, 0);
	display: inline-block;
	width: 188px;
	color: rgba(255, 255, 255, 0.8);
}

.app-sidebar.collapsed .sidebar-navigation .outer-list>li:hover>a svg path {
	fill: #fff;
}

.app-sidebar.collapsed .sidebar-navigation .outer-list>li.has-child-nav:hover>a {
	border-radius: 0 3px 0 0;
}

.app-sidebar.collapsed .sidebar-navigation .outer-list>li:hover>a>span {
	padding-left: 48px;
	width: 188px;
	transform: translate(0, 0);
	margin-left: 0;
	display: inline-block;
}

.app-sidebar.collapsed .sidebar-navigation .outer-list>li .inner-list {
	width: 0px;
	position: absolute;
	top: 48px;
	left: 72px;
	background: #372654;
	overflow: hidden;
	padding: 0 !important;
	border-radius: 0 0 3px 0;
}

.app-sidebar.collapsed .sidebar-navigation .outer-list>li:hover .inner-list {
	max-height: 500px;
	width: 192px;
}

.app-sidebar.collapsed .sidebar-navigation .outer-list>li .inner-list>li>a {
	padding-left: 24px;
}

/* SITE CONTENT */

.app-content {





	transition: all 0.3s;
}

.app-content.nav-collapsed {
	left: 72px;
}

section.site-content {


	position: relative;
}

section.site-content .main-content {
	/*overflow-y: hidden;*/

	overflow: auto;

}

section.site-content .main-content:hover {
	/*overflow: auto;*/
}

header.breadcrumb {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	font-size: 11px;
	line-height: 16px;
	padding: 4px 40px;
	background: #f2f2f2;
	height: 24px;
	z-index: 104;
	transition: all 0.3s;
	background-color: #f5f5f5;
}

.app-content.nav-collapsed header.breadcrumb {
	padding: 4px 24px;
}

.app-content section.site-content header.breadcrumb a {
	color: #546a7b;
}

.app-content section.site-content header.breadcrumb a.active {
	font-weight: 600;
	text-transform: capitalize;
}

.app-content section.site-content header.breadcrumb span {
	display: inline-block;
	padding: 0 9px;
	color: #a1a7af;
}

.app-content section.site-content header.breadcrumb.hidden {
	top: -40px;
}

.app-content section.site-content .main-content .inner-content {

	/* margin: 0px 56px 0 76px; */


}

.app-content.nav-collapsed section.site-content .main-content .inner-content {
	padding: 24px 24px 24px 24px;
	position: relative;
}

/* MAIN CONTENT TOP BAR */

.inner-content .top-bar {
	height: 56px;
	margin: 24px 0;
}

.inner-content .top-bar .top-bar-icon {
	position: relative;
	display: inline-block;
	width: 32px;
	height: 20px;
}

.inner-content .top-bar .top-bar-icon img {
	position: absolute;
	top: 0;
	left: 0;
}

.inner-content .top-bar .top-bar-search {
	display: inline-block;
}

.inner-content .top-bar .top-bar-search input {
	border-radius: 40px;
	width: 40px;
	padding: 16px 10px 10px 48px;
	background-color: transparent;
	background-image: url(../img/searchGray.svg);
	background-repeat: no-repeat;
	background-position: 26px 10px;
	color: #7e95a3;
	border: 1px solid transparent;
	text-align: left;
	transition: all 0.3s;
	transition-timing-function: cubic-bezier(.56, .75, .59, 1.54);
}

.inner-content .top-bar .top-bar-search input::placeholder {
	color: #7e95a3;
}

.inner-content .top-bar .top-bar-search input.active,
.inner-content .top-bar .top-bar-search input:focus {
	width: 240px;
	border-color: #7e95a3;
	background-position: 10px 10px;
}

/* PLANS SELECT OVERLAY DESIGN */

.pt-container li>.edit-plan .duration mat-select:first-child .mat-select-trigger,
.plans-container li>.edit-plan .duration mat-select:first-child .mat-select-trigger {
	min-width: 48px !important;
	height: 36px;
	border-radius: 3px;
	background-color: #ffffff;
	border: solid 1px #dfe3e9;
}

.pt-container li>.edit-plan .duration mat-select:last-child .mat-select-trigger,
.plans-container li>.edit-plan .duration mat-select:last-child .mat-select-trigger {
	min-width: 78px !important;
	height: 36px;
	border-radius: 3px;
	margin-left: 4px;
	background-color: #ffffff;
	border: solid 1px #dfe3e9;
}

.pt-container li>.edit-plan .duration mat-select .mat-select-value,
.plans-container li>.edit-plan .duration mat-select .mat-select-value {
	font-size: 14px;
	line-height: 34px;
	margin-left: 12px;
}

.cdk-overlay-pane {
	position: absolute;
	background: #fff;
	opacity: 1.0;
	z-index: 9999;
}

.cdk-overlay-pane mat-option {
	line-height: 16px;
	font-size: 13px;
	cursor: pointer;
	padding: 10px;
	display: block;
	height: auto !important;
}

.mat-option.mat-selected:not(.mat-option-multiple) {
	background: transparent !important;
}

.cdk-overlay-pane .mat-select-panel::-webkit-scrollbar {
	width: 4px;
}

.cdk-overlay-pane .mat-select-panel::-webkit-scrollbar-track {
	background: rgba(255, 255, 255, 0.0);
	border-radius: 20px;
}

.cdk-overlay-pane .mat-select-panel::-webkit-scrollbar-thumb {
	background: #ced0da;
	border-radius: 20px;
}

.cdk-overlay-pane .mat-select-panel {
	min-width: 130% !important;
	width: 130% !important;
	padding: 8px 0;
	border-radius: 3px;
	background: #fff;
	text-align: center;
	box-shadow: 0 8px 10px 0 rgba(12, 30, 44, 0.2), 0 6px 30px 5px rgba(12, 30, 44, 0.12), 0 16px 24px 2px rgba(12, 30, 44, 0.14);
}

.pt-container li>.edit-plan mat-select .mat-select-arrow,
.plans-container li>.edit-plan mat-select .mat-select-arrow {
	width: 6px;
	height: 6px;
	border-left: 1px solid #7e95a3;
	border-bottom: 1px solid #7e95a3;
	border-top: 1px solid transparent;
	border-right: 1px solid transparent;
	transform: rotate(-45deg);
	margin: 0 8px;
}

.pt-container li>.edit-plan mat-select .mat-select-underline,
.plans-container li>.edit-plan mat-select .mat-select-underline {
	height: 0;
}

/* ADD DISCOUNTS SELECT DROPDOWN*/

.membership-radio {
	display: block !important;
	margin: 8px 0;
}

.membership-radio .mat-radio-label-content {
	color: #0D1F2D;
	font-size: 13px;
	line-height: 16px;
}

#amount-type .mat-select-trigger {
	min-width: 60px;
	height: 34px !important;
	border-radius: 0 3px 3px 0;
	background-color: #f5f6f8;
	border: 0;
	border-left: solid 1px #dfe3e9;
	transform: translate(-2px, 0);
	font-size: 13px;

	padding: 11px 12px;
	color: #7e95a3;
	height: 16px;
	display: inline-block;
}

#amount-type .mat-select-value {
	font-size: 13px;
	line-height: 33px;
	margin-left: 12px;
	height: 34px;
	color: #0D1F2D;
}

#amount-type .mat-select-arrow {
	width: 7px;
	height: 7px;
	border-left: 1px solid #7f97a6;
	border-bottom: 1px solid #7f97a6;
	border-top: 0;
	border-right: 0;
	display: inline-block;
	transform: rotate(-45deg);
	position: absolute;
	right: 5px;
	top: 11px;
}

#amount-type .mat-select-underline {
	height: 0;
}

/* DISCOUNT PROFILE & CUSTOMER PROFILE DATEPICKER */

.discount-profile-details #amount-type .mat-select-trigger {
	height: 30px !important;
}

.discount-profile-details .edit-mode tr>td:last-child {
	padding-left: 24px;
}

.discount-profile-details .edit-mode td>input,
.customer-profile-details .edit-mode td>input,
.staff-profile-details .edit-mode td>input,
.discount-profile-details .edit-mode td>textarea,
.customer-profile-details .edit-mode td>textarea,
.staff-profile-details .edit-mode td>textarea {
	width: 40%;
	height: 32px;
	line-height: 16px;
	padding: 8px 7px;
	border-radius: 3px;
	border: 1px solid #dfe3e9;
	font-family: "Lato";
}

.discount-profile-details .edit-mode td>input:hover,
.customer-profile-details .edit-mode td>input:hover,
.staff-profile-details .edit-mode td>input:hover,
.discount-profile-details .edit-mode td>textarea:hover,
.customer-profile-details .edit-mode td>textarea:hover,
.staff-profile-details .edit-mode td>textarea:hover {
	border: 1px solid #ced0da;
}

.discount-profile-details .edit-mode td>input.invalid,
.staff-profile-details .edit-mode td>input.invalid,
.customer-profile-details .edit-mode td>input.invalid,
.discount-profile-details .edit-mode td>textarea.invalid,
.staff-profile-details .edit-mode td>textarea.invalid,
.customer-profile-details .edit-mode td>textarea.invalid {
	border: solid 1px #EF767A;
}

.discount-profile-details .edit-mode md2-datepicker,
.staff-profile-details .edit-mode md2-datepicker,
.customer-profile-details .edit-mode md2-datepicker {
	min-width: 40% !important;
	width: 40%;
}

.discount-profile-details .edit-mode .md2-datepicker-trigger,
.staff-profile-details .edit-mode .md2-datepicker-trigger,
.customer-profile-details .edit-mode .md2-datepicker-trigger {
	padding: 0 !important;
	margin: 0;
	height: 32px !important;
}

.discount-profile-details .edit-mode .md2-datepicker-trigger .md2-datepicker-input,
.staff-profile-details .edit-mode .md2-datepicker-trigger .md2-datepicker-input,
.customer-profile-details .edit-mode .md2-datepicker-trigger .md2-datepicker-input {
	height: 32px !important;
}

.discount-profile-details .edit-mode .md2-datepicker-trigger button,
.staff-profile-details .edit-mode .md2-datepicker-trigger button,
.customer-profile-details .edit-mode .md2-datepicker-trigger button {
	top: 0 !important;
}

.discount-profile-details .edit-mode .md2-datepicker-trigger .md2-datepicker-placeholder,
.staff-profile-details .edit-mode .md2-datepicker-trigger .md2-datepicker-placeholder,
.customer-profile-details .edit-mode .md2-datepicker-trigger .md2-datepicker-placeholder {
	padding: 0 !important;
}

.discount-profile-details .membership-card-container .discount-membership-card-selection {
	width: auto;
}

/* POS PRODUCTS SELECT CUSTOMIZATION */

.pos-products .secondary-tabs mat-select {
	min-width: 240px;
	border: 1px solid #dfe3e9;
	border-radius: 3px;
	box-sizing: border-box;
	margin: 0 0 16px 16px;
}

.pos-products .secondary-tabs mat-select .mat-select-trigger {
	height: 38px;
	min-width: 240px;
	width: 240px;
}

.pos-products .secondary-tabs mat-select .mat-select-underline {
	display: none;
}

.pos-products .secondary-tabs mat-select .mat-select-value {
	font-size: 14px;
	line-height: 38px !important;
	height: 40px;
	padding-left: 8px;
	min-width: 240px;
	width: 240px;
}

.pos-products .secondary-tabs mat-select .mat-select-arrow {
	width: 8px;
	height: 8px;
	border: none;
	border-left: 2px solid #7e95a3;
	border-bottom: 2px solid #7e95a3;
	transform: rotate(-45deg);
	margin: -2px 10px 0 0;
}

/*POS CART DATEPICKER CUSTOMIZATION*/

.pos-cart .item-container>ul>li>aside>.main>ul>li md2-datepicker {
	height: 32px !important;
}

.pos-cart .item-container>ul>li>aside>.main>ul>li md2-datepicker>.md2-datepicker-trigger {
	padding: 0 !important;
	margin: 0 !important;
}

.pos-cart .item-container>ul>li>aside>.main>ul>li md2-datepicker>.md2-datepicker-trigger,
.pos-cart .item-container>ul>li>aside>.main>ul>li md2-datepicker .md2-datepicker-input {
	min-width: 136px;
	width: 136px;
}

.pos-cart .item-container>ul>li>aside>.main>ul>li md2-datepicker .md2-datepicker-button {
	top: 0px !important;
}

.pos-cart .item-container>ul>li>aside>.main>ul>li md2-datepicker .md2-floating-placeholder {
	display: none !important;
}

/* CUSTOMER FORM SELECT BOX */

#add-customer-form>form mat-select,
#add-prospect-form>form mat-select,
.customer-profile-details .edit-mode mat-select {
	width: 100%;
	border: 1px solid #dfe3e9;
	border-radius: 3px;
	box-sizing: border-box;
	margin: 14px 0;
}

.customer-profile-details .edit-mode mat-select {
	margin: 0;
	height: 32px;
}

#add-customer-form>form mat-select .mat-select-trigger,
#add-prospect-form>form mat-select .mat-select-trigger {
	height: 34px;
}

.customer-profile-details .edit-mode mat-select .mat-select-trigger {
	height: 30px;
}

#add-customer-form>form mat-select .mat-select-underline,
#add-prospect-form>form mat-select .mat-select-underline,
.customer-profile-details .edit-mode mat-select .mat-select-underline {
	display: none;
}

#add-prospect-form>form mat-select .mat-select-value,
#add-customer-form>form mat-select .mat-select-value {
	font-size: 13px;
	line-height: 34px !important;
	height: 36px;
	padding-left: 8px;
}

.customer-profile-details .edit-mode mat-select .mat-select-value {
	line-height: 30px !important;
	font-size: 13px;
	padding-left: 8px;
	height: 30px;
}

#add-customer-form>form mat-select .mat-select-arrow,
#add-prospect-form>form mat-select .mat-select-arrow,
.customer-profile-details .edit-mode mat-select .mat-select-arrow {
	width: 6px;
	height: 6px;
	border-left: 1px solid #7e95a3;
	border-bottom: 1px solid #7e95a3;
	border-top: 1px solid transparent;
	border-right: 1px solid transparent;
	transform: rotate(-45deg);
	margin: 0 8px;
}


#customer-modal md2-datepicker,
#customer-modal md2-datepicker .md2-datepicker-trigger,
#customer-modal md2-datepicker .md2-datepicker-trigger .md2-datepicker-input {
	width: 136px;
	min-width: 136px;
}

#customer-modal md2-datepicker .md2-datepicker-trigger {
	padding: 12px 0 6px 0;
	height: 54px !important;
}

#customer-modal md2-datepicker .md2-datepicker-trigger .md2-datepicker-input .md2-floating-placeholder {
	display: none;
}

#customer-modal .sms-sign-select mat-select {
	padding-top: 0px;
}

#customer-modal .sms-sign-select .mat-select-trigger {
	min-height: 20px;
	max-height: 20px;
}

#prospect-modal .sms-sign-select mat-select {
	padding-top: 0px;
}

#prospect-modal .sms-sign-select .mat-select-trigger {
	min-height: 20px;
	max-height: 20px;
}

#prospect-modal md2-datepicker,
#prospect-modal md2-datepicker .md2-datepicker-trigger,
#prospect-modal md2-datepicker .md2-datepicker-trigger .md2-datepicker-input {
	width: 136px;
	min-width: 136px;
}

#prospect-modal md2-datepicker .md2-datepicker-trigger {
	padding: 12px 0 6px 0;
	height: 54px !important;
}

#prospect-modal md2-datepicker .md2-datepicker-trigger .md2-datepicker-input .md2-floating-placeholder {
	display: none;
}

#resizable-task-modal md2-datepicker,
#resizable-task-modal md2-datepicker .md2-datepicker-trigger,
#resizable-task-modal md2-datepicker .md2-datepicker-trigger .md2-datepicker-input {
	width: 176px !important;
	min-width: 176px !important;
}

#resizable-task-modal md2-datepicker,
#resizable-task-modal md2-datepicker .md2-datepicker-trigger {
	display: inline !important;
	padding: 0 !important;
	height: 36px !important;
}

#resizable-task-modal md2-datepicker .md2-datepicker-trigger .md2-datepicker-input .md2-floating-placeholder {
	display: none;
}

#resizable-task-modal md2-datepicker .md2-datepicker-trigger .md2-datepicker-button {
	top: 0 !important;
	right: -40px !important;
}

/* Overriding background color of date input in billing report*/
#billing_report_period md2-datepicker .md2-datepicker-trigger .md2-datepicker-input {
	background: #ffffff;
}


#resizable-task-modal::-webkit-scrollbar {
	width: 4px;
}

#resizable-task-modal::-webkit-scrollbar-track {
	background: rgba(255, 255, 255, 0.0);
	border-radius: 20px;
}

#resizable-task-modal::-webkit-scrollbar-thumb {
	background: #ced0da;
	border-radius: 20px;
}

.sms-sign-select-panel {
	position: relative;
	left: 16px;
	width: 150px !important;
	min-width: 150px !important;
}

customer-attendance-component header,
staff-attendance-component header {
	border-top: 1px solid #dfe3e9;
	border-left: 1px solid #dfe3e9;
	border-right: 1px solid #dfe3e9;
	border-radius: 5px 5px 0 0;
	overflow: hidden;
	background: #f5f7fa;
	display: block;
	height: 48px;
	padding: 14px 16px;
	text-align: center;

}

customer-attendance-component header .nav-left,
staff-attendance-component header .nav-left {
	float: left;
	margin-left: 16px;
	transform: rotate(180deg);
}

customer-attendance-component header .nav-right,
staff-attendance-component header .nav-right {
	float: right;
	margin-right: 16px;
}

customer-attendance-component header .month-year,
staff-attendance-component header .month-year {
	text-align: center;
	display: inline-block;
}

customer-attendance-component header .month-year p,
staff-attendance-component header .month-year p {
	height: 20px;
	font-size: 16px;
	font-weight: 600;
	display: inline-block;
	color: #546a7b;
}

.customer-attendance-filled-box,
.staff-attendance-filled-box {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.customer-attendance-filled-box>div,
.staff-attendance-filled-box>div {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(222, 71, 76, 0.05);
	border-right: 1px solid #de474c;
}

.customer-attendance-filled-box>div>p {
	position: absolute;
	top: 8px;
	left: 8px;

	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
	color: #d9575b;
}

.staff-attendance-filled-box>div>p:first-child {
	position: absolute;
	top: 8px;
	left: 8px;

	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
	color: #7e95a3;
}

.staff-attendance-filled-box>div>p:last-child {
	position: absolute;
	top: 26px;
	left: 8px;

	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
	color: #43c081;
}

.customer-attendance-filled-box>div>span,
.staff-attendance-filled-box>div>span {
	position: absolute;
	top: 24px;
	left: 8px;
	font-size: 11px;
	line-height: 13px;
	color: #de474c;
}

.customer-attendance-filled-box>div.success,
.staff-attendance-filled-box>div.success {
	background: #f5fcf8;
	border-right: 1px solid #00b898;
}

.customer-attendance-filled-box>div.success>p {
	color: #43c081;
}

mwl-calendar-month-view-header .cal-cell-row {
	border-left: 1px solid #dfe3e9;
	border-right: 1px solid #dfe3e9;
}

mwl-calendar-month-view-header .cal-cell-row>div {
	line-height: 18px !important;
	font-size: 14px !important;
	text-align: center !important;
	color: #7e95a3 !important;
	font-weight: 400 !important;
	padding: 16px 0 !important;
}

mwl-calendar-month-view mwl-calendar-month-cell {
	border-color: #f5f7fa !important;
	min-height: 64px !important;
	height: 64px !important;
}

mwl-calendar-month-view .cal-cell-row .cal-cell:hover {
	background: initial !important;
}

mwl-calendar-month-view .cal-cell-row .cal-day-cell {
	min-height: 64px !important;
	height: 64px !important;
	position: relative !important;
	display: inline-block !important;
}

mwl-calendar-month-view .cal-cell-row .cal-day-cell .cal-day-number,
.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
	line-height: 16px;
	font-size: 13px;
	position: absolute;
	right: 8px;
	bottom: 10px;
	color: #7e95a3;
	margin: 0 !important;
	opacity: 1.0 !important;
}

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
	opacity: 0.4 !important;
}

.cal-month-view .cal-day-cell.cal-today {
	background: initial !important;
}

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
	font-size: 13px !important;
	line-height: 16px !important;
	color: #7e95e3;
}

.cal-month-view .cal-days .cal-cell-row {
	border-color: #f5f7fa !important;
}

.cal-month-view .cal-cell-row:hover {
	background: initial !important;
}

.cal-month-view .cal-days {
	border-top: 1px solid #f5f7f2;
	border-bottom: 1px solid #dfe3e9;
	border-left: 1px solid #dfe3e9;
	border-right: 1px solid #dfe3e9;
	border-radius: 0 0 5px 5px !important;
	overflow: hidden;
}

/* CONTACT FORMLINK DATEPICKER AND SELECT BOX */

.form-link-datepicker md2-datepicker {
	height: 48px;
}

.form-link-datepicker md2-datepicker .md2-datepicker-trigger {
	padding: 0 !important;
	height: 48px !important;
}

.form-link-datepicker md2-datepicker .md2-datepicker-trigger .md2-datepicker-input {
	height: 48px !important;
	padding: 18px 0 8px 0 !important;
	border-left: none !important;
	border-right: none !important;
	border-top: none !important;
	padding-left: 0px !important;
}

.form-link-datepicker md2-datepicker .md2-datepicker-trigger .md2-datepicker-input .md2-datepicker-placeholder {
	top: 15px;
	margin-left: -8px;
	font-size: 16px;

}

.form-link-datepicker md2-datepicker .md2-datepicker-trigger .md2-datepicker-input .md2-floating-placeholder {
	display: none;
}

.form-link-datepicker md2-datepicker .md2-datepicker-trigger .md2-datepicker-input .md2-datepicker-value {
	padding: 0;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0px;
	color: #0d1f2d;
	width: 100%;
}

.form-link-selectbox mat-select {
	width: 100%;
}

.form-link-selectbox .mat-select-value {

	font-weight: 600;
}

.contact-section ng2-file-input .ng2-file-input {
	position: relative;
	height: auto;
	width: 100%;
}

.contact-section ng2-file-input .ng2-file-input .ng2-file-input-invalid {
	font-size: 13px;
	line-height: 18px;
	width: 100%;
	text-align: center;
	margin-bottom: 16px;
}

.contact-section ng2-file-input .ng2-file-input-drop-container {
	height: 208px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-section ng2-file-input.is_profile_pic .ng2-file-input-drop-container {
	display: none;
}

.contact-section ng2-file-input .ng2-file-input-drop-container span {
	height: 160px;
	width: 160px;
	color: #fff;
	position: relative;
}

.contact-section ng2-file-input .ng2-file-input-drop-container span:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 11;
	background-image: url(../img/formlink-profile.svg);
	background-repeat: no-repeat;
	background-position: 0px 0px;
}

.contact-section ng2-file-input .ng2-file-input-drop-container button {
	height: 32px;
	min-width: 120px;
	position: static;
	background: none;
	margin-top: 16px;
	border: 1px solid #7850FA;
	color: #7850FA;
	padding: 0;
}

.contact-section ng2-file-input .ng2-file-input>input {
	display: none
}

.contact-section ng2-file-input .ng2-file-input-files {
	display: none;
}

.contact-section ng2-file-input.is_profile_pic .ng2-file-input-files {
	height: 208px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-section ng2-file-input .ng2-file-input-files .ng2-file-input-file {
	height: 160px;
	width: 160px;
	border-radius: 80px;
	position: relative;
}

.contact-section ng2-file-input .ng2-file-input-files .ng2-file-input-file span:first-child {
	display: none;
}

.contact-section ng2-file-input .ng2-file-input-files .ng2-file-input-file span:last-child {
	display: inline-block;
	position: absolute;
	left: 15px;
	bottom: -47px;
	width: 120px;
	height: 32px;
	border-radius: 3px;
	border: solid 1px #d9575b;
	font-size: 13px;
	font-weight: 600;

	padding: 8px 0;
	line-height: 16px;
	color: #d9575b;
	text-align: center;
	text-transform: uppercase;
	cursor: pointer;
}

.contact-section ng2-file-input .ng2-file-input-files .ng2-file-input-file img {
	height: 160px;
	width: 160px;
	border-radius: 80px;
	overflow: hidden;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}

.contact-form .field input {
	font-family: "Dosis";
}

/* CAMPAIGN MODULE CSS OVERRIDE */

.campaign-list-type-radio .mat-radio-label-content {
	color: #0D1F2D;
	font-size: 13px;
	line-height: 16px;
}

.campaign-launch-type-radio[name="send-now-radio"] {
	display: block !important;
}

.campaign-launch-type-radio[name="send-now-radio"] .mat-radio-label-content {
	color: #0D1F2D;
	font-size: 13px;
	line-height: 16px;
}

.campaign-launch-type-radio-group .md2-datepicker-input .md2-datepicker-placeholder,
.edit-automation-form .md2-datepicker-input .md2-floating-placeholder {
	display: none;
}

.campaign-filter-select {
	border-radius: 8px !important;
	background-color: #ffffff !important;
	box-shadow: 0 4px 5px 0 rgba(13, 31, 45, 0.2), 0 3px 14px 3px rgba(13, 31, 45, 0.12), 0 8px 10px 1px rgba(13, 31, 45, 0.14) !important;
}

.campaign-filter-select mat-option {
	font-weight: 600;
	color: #546a7b;
}

.campaign-filters mat-select {
	min-width: 124px;
	border: 1px solid #dfe3e9;
	background: #dfe3e9;
	border-radius: 3px;
	box-sizing: border-box;
	float: left;
}

.campaign-filters mat-select .mat-select-trigger {
	height: 36px;
	min-width: 124px;
	width: 124px;
}

.campaign-filters mat-select .mat-select-underline {
	display: none;
}

.campaign-filters mat-select .mat-select-value {
	font-size: 13px;
	line-height: 34px !important;
	height: 36px;
	padding-left: 8px;
	min-width: 124px;
	color: #546a7b;
}

.campaign-filters mat-select .mat-select-value-text {
	font-weight: 600;
}

.campaign-filters mat-select .mat-select-arrow {
	width: 8px;
	height: 8px;
	border: none;
	border-left: 2px solid #546a7b;
	border-bottom: 2px solid #546a7b;
	transform: rotate(-45deg);
	margin: -2px 10px 0 0;
}

.sms-sign-select .mat-select-trigger {
	min-height: 82px;
	min-width: 200px;
	height: auto !important;
	border: 0 !important;
}

.sms-sign-select .mat-select-value {
	right: 0;
}

.sms-sign-select .mat-select-value-text {
	position: absolute;
	top: 0;
	left: 16px;
	font-size: 13px;
	line-height: 16px;
	white-space: pre-wrap;
}

.sms-sign-select .mat-select-arrow {
	border: 0 !important;
	position: absolute;
	top: 0;
	left: 0;
}

.sms-sign-select .mat-select-arrow:after,
.sms-sign-select .mat-select-arrow:before {
	content: "";
	position: absolute;
	top: 2px;
	left: 0px;
	border-left: 1px solid #7e95a3;
	border-top: 1px solid #7e95a3;
	height: 6px;
	width: 6px;
	transform: rotate(45deg);
}

.sms-sign-select .mat-select-arrow:before {
	top: 8px;
	transform: rotate(-135deg);
}

.sms-sign-select .mat-select-underline {
	display: none;
}

.success-rate .mat-progress-bar-fill::after {
	background-color: #00b898;
	border-radius: 10px
}

.success-rate .mat-progress-bar-buffer {
	background-color: #f3f5f8;
	;
	border-radius: 10px;
}

/* GMDAY ACCOUNT CSS OVERRIDE */
.gymday-account-details mat-progress-bar,
.gymday-account-subscription mat-progress-bar {
	height: 10px;
}

.gymday-account-details .mat-progress-bar-fill::after,
.gymday-account-subscription .mat-progress-bar-fill::after {
	background-color: #db3186;
	border-radius: 10px
}

.gymday-account-details .mat-progress-bar-buffer,
.gymday-account-subscription .mat-progress-bar-buffer {
	background-color: #f3f5f8;
	border-radius: 10px;
}

/* PROSPECT PROFILE CSS OVERRIDE */

mat-select#prospect-lead .mat-select-trigger {
	width: 75px;
	min-width: 75px;
}

mat-select#prospect-lead .mat-select-underline {
	display: none;
}

mat-select#prospect-lead .mat-select-value {
	font-size: 13px;
	font-weight: 600;

	line-height: 16px;
	text-align: left;
	padding-left: 8px;
	width: 64px;
	text-transform: capitalize;
	display: inline-block;
	color: #7e95a3;
}

mat-select#prospect-lead .mat-select-arrow {
	border-top-color: #7e95a3 !important;
}

mat-select#prospect-lead[data-status='hot'] .mat-select-value {
	color: #de474c;
}

mat-select#prospect-lead[data-status='normal'] .mat-select-value {
	color: #f8b100;
}

#stage-add-popup-select mat-select,
.modal-body.convert_to_lead mat-select {
	min-width: 100%;
	border: 1px solid #dfe3e9;
	border-radius: 3px;
	box-sizing: border-box;
	float: left;
}

#stage-add-popup-select mat-select .mat-select-trigger,
.modal-body.convert_to_lead mat-select .mat-select-trigger {
	height: 36px;
	min-width: 100%;
	width: 100%;
}

#stage-add-popup-select mat-select .mat-select-underline,
.modal-body.convert_to_lead mat-select .mat-select-underline {
	display: none;
}

#stage-add-popup-select mat-select .mat-select-value,
.modal-body.convert_to_lead mat-select .mat-select-value {
	font-size: 13px;
	line-height: 34px !important;
	height: 36px;
	padding-left: 8px;
	min-width: 100%;
	color: #546a7b;
}

#stage-add-popup-select mat-select .mat-select-arrow,
.modal-body.convert_to_lead mat-select .mat-select-arrow {
	width: 8px;
	height: 8px;
	border: none;
	border-left: 2px solid #dfe3e9;
	border-bottom: 2px solid #dfe3e9;
	transform: rotate(-45deg);
	margin: -2px 10px 0 0;
}

/* POS DESTROTY POPUP OVERRIDE */

.posDestroy {
	border-radius: 10px;
	overflow: hidden;
}

mat-dialog-container {
	height: 240px !important;
	width: 448px !important;
	padding: 24px 16px 16px 16px !important;
	border-radius: 10px !important;
	overflow: hidden !important;
	background-color: #ffffff !important;
	box-shadow: 0 7px 8px 0 rgba(13, 31, 45, 0.2), 0 5px 22px 4px rgba(13, 31, 45, 0.12), 0 12px 17px 2px rgba(13, 31, 45, 0.14) !important;
	border: solid 1px #dfe3e9 !important;
}

confirm-dialog h2 {
	line-height: 22px;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	margin: 0;
	padding: 0;
	color: #546a7b;
	margin-bottom: 24px;
	padding: 0 !important;
}

confirm-dialog mat-dialog-content {
	height: 80px !important;
	display: block !important;
	text-align: center !important;
	margin-bottom: 34px !important;
	padding: 0 !important;
	width: 100% !important;
	float: left !important;
}

confirm-dialog mat-dialog-actions {
	padding: 0 !important;
	display: block !important;
}

confirm-dialog mat-dialog-actions button {
	width: 199px;
	float: left;
}

/* REPORTS CSS OVERRIDE */

.reports-period-div md2-datepicker {
	width: 128px;
	min-width: 128px;
}

.reports-period-div md2-datepicker .md2-datepicker-trigger .md2-datepicker-input .md2-floating-placeholder {
	display: none;
}

.reports-period-div md2-datepicker .md2-datepicker-trigger {
	padding: 0 !important;
	height: 36px !important;
}

.reports-period-div md2-datepicker .md2-datepicker-trigger .md2-datepicker-input {
	width: 128px;
	min-width: 128px;
}

.reports-period-div md2-datepicker .md2-datepicker-button {
	top: 0 !important;
}

.reports-filter-div-select-overlay {
	border-radius: 8px !important;
	background-color: #ffffff !important;
	box-shadow: 0 4px 5px 0 rgba(13, 31, 45, 0.2), 0 3px 14px 3px rgba(13, 31, 45, 0.12), 0 8px 10px 1px rgba(13, 31, 45, 0.14) !important;
}

.reports-filter-div-select-overlay mat-option {
	font-weight: 600;
	color: #546a7b;
}

.reports-filter-div {
	height: 52px;
	overflow: visible;
}

.reports-filter-div mat-select {
	min-width: 124px;
	border: 1px solid #dfe3e9;
	background: #dfe3e9;
	border-radius: 3px;
	box-sizing: border-box;
	float: left;
}

.reports-filter-div mat-select .mat-select-trigger {
	height: 36px;
	min-width: 124px;
	width: 124px;
}

.reports-filter-div mat-select .mat-select-underline {
	display: none;
}

.reports-filter-div mat-select .mat-select-value {
	font-size: 13px;
	line-height: 34px !important;
	height: 36px;
	padding-left: 8px;
	min-width: 124px;
	color: #546a7b;
}

.reports-filter-div mat-select .mat-select-value-text {
	font-weight: 600;
}

.reports-filter-div mat-select .mat-select-arrow {
	width: 8px;
	height: 8px;
	border: none;
	border-left: 2px solid #546a7b;
	border-bottom: 2px solid #546a7b;
	transform: rotate(-45deg);
	margin: -2px 10px 0 0;
}

.reports-filter-div .payment-status,
.reports-filter-div .payment-mode,
.reports-filter-div .access-filter {
	position: relative;
	display: inline-block;
	margin-left: 16px;
}

.reports-filter-div .payment-status p,
.reports-filter-div .payment-mode p,
.reports-filter-div .access-filter p {
	position: relative;
	width: 136px;
	height: 38px;
	border-radius: 3px;
	background-color: #dfe3e9;
	border: solid 1px #dfe3e9;
	line-height: 16px;
	font-size: 13px;
	font-weight: 600;
	text-align: left;
	padding: 10px 8px;
	color: #546a7b;
	cursor: pointer;
}

.reports-filter-div .payment-status p:after,
.reports-filter-div .payment-mode p:after,
.reports-filter-div .access-filter p:after {
	content: '';
	top: 13px;
	right: 10px;
	position: absolute;
	width: 8px;
	height: 8px;
	border: none;
	border-left: 2px solid #546a7b;
	border-bottom: 2px solid #546a7b;
	transform: rotate(-45deg);
}

.reports-filter-div .payment-status aside,
.reports-filter-div .payment-mode aside,
.reports-filter-div .access-filter aside {
	padding: 5px 0;
	position: absolute;
	top: -105px;
	z-index: 99;
	left: 0;
	width: 136px;
	height: 228px;
	border-radius: 8px;
	background-color: #ffffff;
	box-shadow: 0 4px 5px 0 rgba(13, 31, 45, 0.2), 0 3px 14px 3px rgba(13, 31, 45, 0.12), 0 8px 10px 1px rgba(13, 31, 45, 0.14);
}

.reports-filter-div .payment-mode aside {
	height: 156px;
}

.reports-filter-div .access-filter aside {
	top: -35px;
	height: 84px;
}

.reports-filter-div .payment-status aside ul li,
.reports-filter-div .payment-mode aside ul li,
.reports-filter-div .access-filter aside ul li {
	padding: 10px 12px;
	height: 36px;
}

.reports-filter-div .payment-status aside ul li .mat-checkbox-inner-container,
.reports-filter-div .payment-mode aside ul li .mat-checkbox-inner-container,
.reports-filter-div .access-filter aside ul li .mat-checkbox-inner-container {
	top: -3px !important;
}

/* STAFF ADD SELECT BOX */

.staff-permission-radio mat-select {
	min-width: 140px;
	border: 1px solid #dfe3e9;
	border-radius: 3px;
	box-sizing: border-box;
	margin: 0 0 0 16px;
}

.staff-permission-radio span.text {
	position: relative;
	top: 5px;
}

.staff-permission-radio mat-select .mat-select-trigger {
	height: 34px;
	min-width: 140px;
	width: 140px;
}

.staff-permission-radio mat-select .mat-select-underline {
	display: none;
}

.staff-permission-radio mat-select .mat-select-value {
	font-size: 14px;
	line-height: 34px !important;
	height: 36px;
	padding-left: 8px;
	min-width: 240px;
	width: 140px;
}

.staff-permission-radio mat-select .mat-select-arrow {
	width: 8px;
	height: 8px;
	border: none;
	border-left: 2px solid #7e95a3;
	border-bottom: 2px solid #7e95a3;
	transform: rotate(-45deg);
	margin: -2px 10px 0 0;
}


/* PERMISSION SETTINGS */

.photo-setting-select mat-select {
	min-width: 124px;
	border: 1px solid #dfe3e9;
	background: #dfe3e9;
	border-radius: 3px;
	box-sizing: border-box;
	float: left;
}

.photo-setting-select mat-select .mat-select-trigger {
	height: 36px;
	min-width: 124px;
	width: 124px;
}

.photo-setting-select mat-select .mat-select-underline {
	display: none;
}

.photo-setting-select mat-select .mat-select-value {
	font-size: 13px;
	line-height: 34px !important;
	height: 36px;
	padding-left: 8px;
	min-width: 124px;
	color: #546a7b;
}

.photo-setting-select mat-select .mat-select-value-text {
	font-weight: 600;
}

.photo-setting-select mat-select .mat-select-arrow {
	width: 8px;
	height: 8px;
	border: none;
	border-left: 2px solid #546a7b;
	border-bottom: 2px solid #546a7b;
	transform: rotate(-45deg);
	margin: -2px 10px 0 0;
}

.permission-group-setting mat-checkbox .mat-checkbox-label {
	position: relative;
	top: 2px;
}

.permission-group-setting .mat-checkbox-inner-container {
	height: 16px !important;
	width: 16px !important;
}

.customer-migration ng2-file-input .ng2-file-input {
	position: relative;
	height: 40px;
	width: 100%;
}

.customer-migration ng2-file-input .ng2-file-input .ng2-file-input-invalid {
	position: absolute;
	left: 0;
	font-size: 13px;
	top: 50%;
	color: #546a7b;
	transform: translate(0, -50%);
}

.customer-migration ng2-file-input .ng2-file-input-drop-container {
	float: right;
}

.customer-migration ng2-file-input .ng2-file-input-drop-container span:first-child {
	display: none;
}

.customer-migration ng2-file-input .ng2-file-input-drop-container button {
	display: inline-block;
	min-width: 144px;
	font-size: 13px;
	height: 36px;
	padding: 9px;
	line-height: 16px;
	text-align: center;
	border-radius: 3px;
	border: 1px solid transparent;
	position: relative;
	background-color: #7850FA;
	border: solid 1px #7850FA;
	font-weight: 600;
	color: rgba(255, 255, 255, 0.96);
}

.customer-migration .valid-file .ng2-file-input-drop-container button {
	display: none;
}

.customer-migration ng2-file-input .ng2-file-input>input {
	display: none
}

.customer-migration ng2-file-input .ng2-file-input-files {
	float: left;
}

.customer-migration ng2-file-input .ng2-file-input-files .ng2-file-input-file {
	height: 40px;
	overflow: visible;
	width: 100%;
	float: left;
	position: relative;
}

.customer-migration ng2-file-input .ng2-file-input-files .ng2-file-input-file span:first-child {
	display: inline-block;
	padding: 12px 0;
	font-size: 14px;
	line-height: 16px;
	color: #0072a3
}

.customer-migration ng2-file-input .ng2-file-input-files .ng2-file-input-file span:last-child {
	display: none;
}



#prospect-excel-import-modal ng2-file-input .ng2-file-input {
	position: relative;
	height: 40px;
	width: 100%;
	text-align: center;
}

#prospect-excel-import-modal ng2-file-input .ng2-file-input .ng2-file-input-invalid {
	color: #546a7b;
	font-size: 13px;
	line-height: 16px;
	padding-bottom: 8px;
}

#prospect-excel-import-modal ng2-file-input .ng2-file-input-drop-container {
	display: inline-block;
}

#prospect-excel-import-modal ng2-file-input .ng2-file-input-drop-container span:first-child {
	display: none;
}

#prospect-excel-import-modal ng2-file-input .ng2-file-input-drop-container button {
	display: inline-block;
	min-width: 144px;
	font-size: 13px;
	height: 36px;
	padding: 9px;
	line-height: 16px;
	text-align: center;
	border-radius: 3px;
	border: 1px solid transparent;
	position: relative;
	background-color: #eb3b8c;
	border: solid 1px #eb3b8c;
	color: rgba(255, 255, 255, 0.96);
}

#prospect-excel-import-modal .valid-input .ng2-file-input-drop-container {
	display: none;
}

#prospect-excel-import-modal ng2-file-input .ng2-file-input>input {
	display: none
}

#prospect-excel-import-modal ng2-file-input .ng2-file-input-files {
	display: inline-block;
	text-align: center;
}

#prospect-excel-import-modal ng2-file-input .ng2-file-input-files .ng2-file-input-file {
	height: 40px;
	overflow: visible;
	width: 100%;
	float: left;
	position: relative;
	text-align: center;
}

#prospect-excel-import-modal ng2-file-input .ng2-file-input-files .ng2-file-input-file span:first-child {
	display: inline-block;
	padding: 12px 0;
	font-size: 14px;
	line-height: 16px;
	color: #0d1f2d;
}

#prospect-excel-import-modal ng2-file-input .ng2-file-input-files .ng2-file-input-file span:last-child {
	display: inline-block;
	min-width: 144px;
	font-size: 13px;
	height: 36px;
	padding: 9px;
	line-height: 16px;
	text-align: center;
	border-radius: 3px;
	position: relative;
	background-color: transparent;
	border: solid 1px #e1454b;
	color: #e1454b;
	margin-left: 10px;

	font-weight: 600;
	text-transform: uppercase;
	cursor: pointer;
}


/* TABLE FILTERS CSS */
.site-content {
	position: relative;
}

.side-filter {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 100vh;
	background: rgba(0, 0, 0, 0.12);
	z-index: 105;
	transition: all 0s;
	transition-delay: 0.5s;
}

.side-filter.active {
	bottom: 0;
	transition-delay: 0s;
}

.side-filter>main {
	position: absolute;
	right: -350px;
	top: 0;
	bottom: 0;
	width: 320px;
	overflow: auto;
	background-color: #fafafa;
	box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
	transition: all 0.5s;
}

.side-filter>main.active {
	right: 0;
	transition: all 0.5s;
}

.side-filter>main.active::-webkit-scrollbar {
	width: 4px;
}

.side-filter>main.active::-webkit-scrollbar-track {
	background: rgba(255, 255, 255, 0.0);
	border-radius: 20px;
}

.side-filter>main.active::-webkit-scrollbar-thumb {
	background: #ced0da;
	border-radius: 20px;
}

.top-bar-filter {
	padding-bottom: 16px !important;
	height: 88px !important;
}

.filter-row {
	height: 28px;
	margin-bottom: 16px;
}

.filter-row>section {
	height: 28px;
	border: 1px solid #00b898;
	border-radius: 14px;
	background: rgba(0, 184, 152, 0.05);
	padding: 6px 24px 6px 16px;
	display: flex;
	justify-content: space-between;
}

.filter-row>section>.actions>button {
	float: left;
	text-transform: uppercase;
}

.side-filter-list {
	background: #fff;
	border-bottom: 1px solid #dfe3e9;
	border-top: 1px solid #dfe3e9;
	padding-right: 16px;
	padding-left: 24px;
}

.side-filter-list>li {
	padding: 17px 0 10px 0;
}

.side-filter-list>li:not(:first-child) {
	border-top: 1px solid #dfe3e9;
}

.side-filter-list>li>label {
	font-size: 13px;
	font-weight: 600;

	color: #7f6ca6;
	line-height: 16px;
	display: block;
	text-transform: uppercase;
}

.side-filter-list>li .datepicker-label {
	display: inline-block;
	width: 45px;
}

.side-filter-list>li .period-datepicker-div md2-datepicker {
	min-width: 128px;
}

.side-filter-list>li .period-datepicker-div md2-datepicker .md2-datepicker-trigger {
	padding: 6px 0 !important;
	height: 48px !important;
}

.side-filter-list>li .period-datepicker-div md2-datepicker .md2-datepicker-trigger .md2-datepicker-button {
	top: 6px !important;
}

.side-filter-list>li .mat-checkbox-label {
	position: relative;
	top: 3px;
}

/* ALIGN !SIGN OF CANCEL POPUP IN CENTER*/

confirm-dialog mat-dialog-content {
	margin-left: 0px !important;
}

.mat-checkbox-inner-container {
	width: 18px !important;
	height: 18px !important;
}

.recurring-payment-cycle {
	width: 48px;
	height: 40px;
	display: inline-block !important;
}

.left79 {
	left: 79%;
}

.left56 {
	left: 56%;
}

.left58_5 {
	left: 58.8%;
}

.left48 {
	left: 48%;
}

.left51 {
	left: 51%;
}

.transform-uppercase{
    text-transform: uppercase;
}


.border-grey{
    &.border-bottom{
        border-bottom:1px solid #dfe3e9;
    }
    &.border-top{
        border-top:1px solid #dfe3e9;
    }
    &.border-left{
        border-left:1px solid #dfe3e9;
    }
    &.border-right{
        border-right:1px solid #dfe3e9;
    }

    &#border-bottom-none{
        border-bottom:none;
    }
    &#border-top-none{
        border-top:none;
    }
    &#border-left-none{
        border-left:none;
    }
    &#border-right-none{
        border-right:none;
    }

}
