@media (max-width:1130px) {
	app-header{
  		left: 72px !important;
  		padding: 0px 0 0px 24px !important;
	}
	app-sidebar{
  		width: 72px !important;
  		overflow: visible !important;
	}
	.app-sidebar{
		width: 72px !important;
		overflow: visible !important;
	}
	.app-sidebar .sidebar-client-info h4{
		height: 0;
		width: 0;
	}
	.app-sidebar .sidebar-client-info p{
		display: block;
	}
	.app-sidebar .sidebar-client-info svg{
		height: 16px;
		width: 16px;
		left: 56px;
		bottom: 0;
		top: 40px;
		display: none
	}
	.app-sidebar  .sidebar-navigation {
	    overflow: visible !important;
	}
	.app-sidebar  .sidebar-navigation>span {
	    font-size: 11px;
	    line-height: 13px;
	    display: block;
	    letter-spacing: 1px;
	    padding: 6px 0;
	    text-align: center;
	}
	.app-sidebar  .sidebar-navigation .outer-list>li {
	    position: relative;
	}
	.app-sidebar  .sidebar-navigation .outer-list>li>a {
	    width: 72px;
	    display: inline-flex;
	    padding: 12px 23px 12px 24px;
	}
	.sidebar-navigation .outer-list>li>a label{
	    position: relative;
	}
	.app-sidebar  .sidebar-navigation .outer-list>li>a>svg {
	    display: flex;
	}
	.app-sidebar  .sidebar-navigation .outer-list>li>a>span {
	    width: 1px;
	    padding-left: 1px;
	    margin-left: 0px;
	    overflow: hidden;
	    display: flex;
	}
	.app-sidebar  .sidebar-navigation .outer-list>li.has-child-nav>a:after {
	    display: none;
	}
	
	.app-sidebar .sidebar-navigation .outer-list>li.active:hover>a>label:after,
	.app-sidebar .sidebar-navigation .outer-list>li.active>a>label:after {
	    content: "";
	    position: absolute;
	    left: -24px;
	    top: -12px;
	    height: 48px;
	    width: 72px;
	    display: inline-block;
	    z-index: -1;
	}
	
	.app-sidebar  .sidebar-navigation .outer-list>li:hover>a>span {
	    padding-left: 47px;
	    margin-left: 0;
	    transform: translate(0, 0);
	    display: inline-block;
	    width: 188px;
	    color: rgba(255, 255, 255, 0.8);
	}
	.app-sidebar  .sidebar-navigation .outer-list>li:hover>a svg path {
	    fill: #fff;
	}
	.app-sidebar  .sidebar-navigation .outer-list>li.has-child-nav:hover>a {
	    border-radius: 0 3px 0 0;
	}
	.app-sidebar  .sidebar-navigation .outer-list>li:hover>a>span {
	    padding-left: 48px;
	    width: 188px;
	    transform: translate(0, 0);
	    margin-left: 0;
	    display: inline-block;
	}
	
	.app-sidebar  .sidebar-navigation .outer-list>li:hover .inner-list {
	    max-height: 500px;
	    width: 192px;
	}
	.app-sidebar  .sidebar-navigation .outer-list>li .inner-list>li>a {
	    padding-left: 24px;
	}
	.app-content{
  		left: 72px !important;
	}
	header.breadcrumb{
		padding: 4px 24px !important;
	}
	section.site-content .main-content .inner-content{
		padding-left: 24px !important;
		padding-right: 24px !important;
	}
}
