/* CSS RESET*/
/* @font-face {
	font-family: 'gordita';
	src:
		url('../fonts/GorditaRegular.otf') format('opentype');
} */
/* RESET CSS */


html,
body {
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    height: 100%;
    font-size: 16px;
    overflow: hidden;
    font-family: 'Lato', sans-serif !important;

}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
}

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box
}

div,
section,
aside,
article,
header,
nav,
footer,
address,
form,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
ul li,
ol li,
span,
img,
a,
table,
thead,
tbody,
tfoot,
tr,
th,
td,
dt,
dd {
    font-family: inherit, sans-serif;
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    background: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
    display: block
}

audio,
canvas,
video {
    display: inline-block;
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden],
template {
    display: none
}

mark {
    background: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -0.5em
}

sub {
    bottom: -0.25em
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

address {
    font-weight: normal;
    font-style: normal
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500
}

h1 {
    font-size: 2em;
    line-height: 1.2;
    padding-bottom: 15px
}

h2 {
    font-size: 1.875em;
    line-height: 1.2;
    padding-bottom: 15px
}

h3 {
    font-size: 1.565em;
    line-height: 1.2;
    padding-bottom: 12px
}

h4 {
    font-size: 1.25em;
    line-height: 1.2;
    padding-bottom: 10px
}

h5 {
    font-size: 1.125em;
    line-height: 1.2;
    padding-bottom: 8px
}

h6 {
    font-size: 1em;
    line-height: 1.2;
    padding-bottom: 5px
}

ul,
ol,
ul li,
ol li {
    list-style: none
}

label,
input,
select,
textarea,
button {
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    background: none;
}

textarea {
    resize: vertical;
    overflow: auto;
    max-height: auto;
}

label,
input[type=text],
input[type=tel],
input[type=email],
input[type=password],
input[type=button],
input[type=submit],
input[type=reset],
input[type=search],
select,
textarea,
button {
    -webkit-appearance: none
}

::selection {
    color: #FFFFFF;
    background: #333333
}

::-moz-selection {
    color: #FFFFFF;
    background: #333333
}

/* CUSTOM CLASSES */

.no-overflow {
    overflow: hidden
}

.clearfix,
.clearfix:before,
.clearfix:after {
    clear: both
}

.clearfix:before,
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    font-size: 0;
    line-height: 0
}

.display-inline {
    display: inline;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.visibility-none {
    visibility: hidden;
}

.thin-font {
    font-weight: 300;
}

.regular-font {
    font-weight: 400;
}

.semibold-font {
    font-weight: 600;
}

.bold-font {
    font-weight: 900;
}

.bold-font-weight {
    font-weight: bold;
}

.clear-left {
    clear: left;
}

.clear-right {
    clear: right;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.align-justify {
    text-align: justify;
}

.cursor-pointer:hover {
    cursor: pointer;
}

.underline:hover {
    text-decoration: underline
}

.no-underline {
    text-decoration: none;
}

.strikethrough {
    text-decoration: line-through;
}

.no-shadow {
    box-shadow: none;
}

.text-uppercase {
    text-transform: uppercase
}

.text-lowercase {
    text-transform: lowercase
}

.text-capitalize {
    text-transform: capitalize
}

.position-relative {
    position: relative
}

.opacity-05 {
    opacity: 0.5;
}

.transparent {
    background: transparent;
}

.no-letter-spacing {
    letter-spacing: 0px
}

.letter-spacing-03 {
    letter-spacing: 0.3px
}

.letter-spacing-05 {
    letter-spacing: 0.5px
}

.letter-spacing-10 {
    letter-spacing: 1.0px
}

.dark-color-text-1 {
    color: #0D1F2D;
}

.dark-color-text-2 {
    color: #546A7B;
}

.dark-color-text-3 {
    color: #7E95A3;
}

.dark-color-background-1 {
    background: #0D1F2D;
}

.dark-color-background-2 {
    background: #546A7B;
}

.dark-color-background-3 {
    background: #7E95A3;
}

.light-color-background-4 {
    background: #fafafa !important;
}

.dark-white-background-5 {
    background-color: #ffffff;
}

.light-color-text-1 {
    color: rgba(255, 255, 255, 0.96);
}

.light-color-text-2 {
    color: rgba(255, 255, 255, 0.7);
}

.light-color-text-3 {
    color: rgba(255, 255, 255, 0.5);
}

.alert-color-text {
    color: #EF767A;
}

.success-color-text {
    color: #56CC91;
}

.success-color-text-dark {
    color: #11b368;
}

.theme-color-text-1 {
    color: #7850FA;
}

.theme-color-text-1-dull {
    color: #7f6ca6;
}

.theme-color-text-2 {
    color: #EB3B8C;
}

.theme-color-text-3 {
    color: #00B898;
}

.theme-color-text-4 {
    color: #0072A3;
}

.orange-color-text {
    color: #f29100;
}

.pink-color-text {
    color: #d01373;
}

.green-color-text {
    color: #43bf81;
}

.red-color-text {
    color: #de474c;
}

.theme-primary-text-color {
    color: #7850FA !important;
}

.smallest-text {
    font-size: 11px;
    line-height: 1.2;
}

.smaller-text {
    font-size: 12px;
    line-height: 1.25;
}

.small-text {
    font-size: 13px;
    line-height: 1.23;
}

.medium-text {
    font-size: 14px;
    line-height: 1.28;
}

.large-text {
    font-size: 16px;
    line-height: 1.25;
}

.larger-text {
    font-size: 20px;
    line-height: normal;
}

.extra-large-text {
    font-size: 28px;
    line-height: 1.25
}

.margin-top0 {
    margin-top: 0 !important;
}

.margin-left0 {
    margin-left: 0 !important;
}

.margin-right0 {
    margin-right: 0 !important;
}

.margin-bottom0 {
    margin-bottom: 0 !important;
}

.margin-top5 {
    margin-top: 5px;
}

.margin-left5 {
    margin-left: 5px;
}

.margin-right5 {
    margin-right: 5px;
}

.margin-bottom5 {
    margin-bottom: 5px;
}

.margin-left6 {
    margin-left: 6px;
}

.margin-right7 {
    margin-right: 7px !important;
}

.margin-top8 {
    margin-top: 8px;
}

.margin-left8 {
    margin-left: 8px;
}

.margin-right8 {
    margin-right: 8px;
}

.margin-bottom8 {
    margin-bottom: 8px;
}

.margin-top10 {
    margin-top: 10px;
}

.margin-left10 {
    margin-left: 10px;
}

.margin-right10 {
    margin-right: 10px;
}

.margin-bottom10 {
    margin-bottom: 10px;
}

.margin-bottom12 {
    margin-bottom: 12px;
}

.margin-top15 {
    margin-top: 15px;
}

.margin-left15 {
    margin-left: 15px;
}

.margin-right15 {
    margin-right: 15px;
}

.margin-bottom15 {
    margin-bottom: 15px;
}

.margin-top16 {
    margin-top: 16px;
}

.margin-left16 {
    margin-left: 16px;
}

.margin-right16 {
    margin-right: 16px;
}

.margin-bottom16 {
    margin-bottom: 16px;
}

.margin-top20 {
    margin-top: 20px;
}

.margin-left20 {
    margin-left: 20px;
}

.margin-right20 {
    margin-right: 20px;
}

.margin-bottom20 {
    margin-bottom: 20px;
}

.margin-top22 {
    margin-top: 22px;
}

.margin-top24 {
    margin-top: 24px;
}

.margin-left24 {
    margin-left: 24px;
}

.margin-right24 {
    margin-right: 24px;
}

.margin-bottom24 {
    margin-bottom: 24px;
}

.margin-top25 {
    margin-top: 25px;
}

.margin-left25 {
    margin-left: 25px;
}

.margin-right25 {
    margin-right: 25px;
}

.margin-bottom25 {
    margin-bottom: 25px;
}

.margin-top30 {
    margin-top: 30px;
}

.margin-left30 {
    margin-left: 30px;
}

.margin-right30 {
    margin-right: 30px;
}

.margin-bottom30 {
    margin-bottom: 30px;
}

.margin-top32 {
    margin-top: 32px;
}

.margin-left32 {
    margin-left: 32px;
}

.margin-right32 {
    margin-right: 32px;
}

.margin-bottom32 {
    margin-bottom: 32px;
}

.margin-top35 {
    margin-top: 35px;
}

.margin-left35 {
    margin-left: 35px;
}

.margin-right35 {
    margin-right: 35px;
}

.margin-bottom35 {
    margin-bottom: 35px;
}

.margin-top40 {
    margin-top: 40px
}

.margin-left40 {
    margin-left: 40px
}

.margin-right40 {
    margin-right: 40px
}

.margin-bottom40 {
    margin-bottom: 40px
}

.margin-right236 {
    margin-right: 236px !important
}

.margin-right200 {
    margin-right: 200px !important
}

.margin-top13 {
    margin-top: 13px
}

.margin-bottom13 {
    margin-bottom: 13px
}

.margin-left13 {
    margin-left: 13px
}

.margin-right13 {
    margin-right: 13px
}

.margin-top68 {
    margin-top: 68px
}

.padding0 {
    padding: 0px;
}

.padding-top0 {
    padding-top: 0 !important;
}

.padding-left0 {
    padding-left: 0 !important;
}

.padding-right0 {
    padding-right: 0 !important;
}

.padding-bottom0 {
    padding-bottom: 0 !important;
}

.padding-top4 {
    padding-top: 4px;
}

.padding-top5 {
    padding-top: 5px;
}

.padding-left5 {
    padding-left: 5px;
}

.padding-right5 {
    padding-right: 5px;
}

.padding-bottom5 {
    padding-bottom: 5px;
}

.padding-top10 {
    padding-top: 10px;
}

.padding-left10 {
    padding-left: 10px;
}

.padding-right10 {
    padding-right: 10px;
}

.padding-bottom10 {
    padding-bottom: 10px;
}

.padding-top8 {
    padding-top: 8px;
}

.padding-left8 {
    padding-left: 8px;
}

.padding-right8 {
    padding-right: 8px;
}

.padding-bottom8 {
    padding-bottom: 8px;
}

.padding-left12 {
    padding-left: 12px;
}

.padding-top13 {
    padding-top: 13px;
}

.padding-top15 {
    padding-top: 15px;
}

.padding-left15 {
    padding-left: 15px;
}

.padding-right15 {
    padding-right: 15px;
}

.padding-bottom15 {
    padding-bottom: 15px;
}

.padding-top16 {
    padding-top: 16px;
}

.padding-left16 {
    padding-left: 16px;
}

.padding-right16 {
    padding-right: 16px;
}

.padding-bottom16 {
    padding-bottom: 16px;
}

.padding-top18 {
    padding-top: 18px;
}

.padding-top20 {
    padding-top: 20px;
}

.padding-left20 {
    padding-left: 20px !important;
}

.padding-right20 {
    padding-right: 20px;
}

.padding-bottom20 {
    padding-bottom: 20px;
}

.padding-left22 {
    padding-left: 22px !important;
}

.padding-top24 {
    padding-top: 24px;
}

.padding-left24 {
    padding-left: 24px;
}

.padding-right24 {
    padding-right: 24px;
}

.padding-bottom24 {
    padding-bottom: 24px;
}

.padding-top25 {
    padding-top: 25px;
}

.padding-left25 {
    padding-left: 25px !important;
}

.padding-right25 {
    padding-right: 25px;
}

.padding-bottom25 {
    padding-bottom: 25px;
}

.padding-right29 {
    padding-right: 29px;
}

.padding-top30 {
    padding-top: 30px;
}

.padding-left30 {
    padding-left: 30px;
}

.padding-right30 {
    padding-right: 30px;
}

.padding-bottom30 {
    padding-bottom: 30px;
}

.padding-top32 {
    padding-top: 32px;
}

.padding-left32 {
    padding-left: 32px;
}

.padding-right32 {
    padding-right: 32px;
}

.padding-bottom32 {
    padding-bottom: 32px;
}

.padding-top35 {
    padding-top: 35px;
}

.padding-left35 {
    padding-left: 35px;
}

.padding-right35 {
    padding-right: 35px;
}

.padding-bottom35 {
    padding-bottom: 35px;
}

.padding-top40 {
    padding-top: 40px;
}

.padding-left44 {
    padding-left: 44px !important;
}

.padding-left40 {
    padding-left: 40px;
}

.padding-right40 {
    padding-right: 40px !important;
}

.padding-bottom40 {
    padding-bottom: 40px;
}

.padding-left50 {
    padding-left: 50px !important;
}

.padding-bottom60 {
    padding-bottom: 60px
}

.padding-bottom70 {
    padding-bottom: 70px !important;
}

.boder-bottom-bright-gray {
    border-bottom: 1px solid #546a7b;
}

.border-bottom-light-gray {
    border-bottom: 1px solid #eaebef;
}

.border-bottom-gray {
    border-bottom: 1px solid #dfe3e9;
}

.border-top-gray {
    border-top: 1px solid #dfe3e9;
}

.border-bottom-light {
    border-bottom: 1px solid #f3f5f8;
}

.border-top-light {
    border-top: 1px solid #f3f5f8;
}

.vert-align-middle {
    vertical-align: middle;
}

.vert-align-top {
    vertical-align: top;
}

.scrollable {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: auto
}

a,
a:hover {
    text-decoration: none
}

a,
input[type=submit],
input[type=reset],
input[type=button],
input[type=file],
button {
    cursor: pointer;
    -moz-transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    -ms-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}

a,
a:hover,
a:focus,
img,
img:hover,
img:focus,
input,
input:hover,
input:focus,
textarea,
textarea:hover,
textarea:focus,
select,
select:hover,
select:focus,
button,
button:hover,
button:focus,
li,
li:hover,
li:focus {
    outline: none;
}

img {
    display: inline-block;
    max-width: 100%;
    vertical-align: bottom;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield; /* Firefox-specific CSS to hide arrows */
}


/* CUSTOM SWITCH DESIGN */

.switch-field {
    height: 20px;
}

.customSwitch {
    display: none;
}

.customSwitch+label {
    display: inline-block;
    width: 38px;
    height: 20px;
    background: #7e95a3;
    border-radius: 10px;
    box-shadow: inset 0 0 0 1.5px #7e95a3;
    margin: 0 5px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
}

.customSwitch+label>i {
    height: 16px;
    position: absolute;
    width: 16px;
    background: #FFF;
    display: inline-block;
    border-radius: 100%;
    top: 2px;
    left: 2px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    box-shadow: 0 0 0 1px #bcbda2;
    pointer-events: none;
    transform: translate(0, 0);
}

.customSwitch[value='active']+label>i {
    transform: translate(18px, 0);
    box-shadow: 0 0 0 1px #7850FA;
}

.customSwitch[value='active']+label {
    box-shadow: inset 0 0 0 20px #7850FA;
    border: none;
}

.customSwitch[value='inactive']+label>i {
    transform: translate(0, 0);
}

.customSwitch[value='active']:disabled+label {
    background: #ae9bcf;
    box-shadow: none;
    cursor: not-allowed;
}

.customSwitch[value='active']:disabled+label>i {
    box-shadow: 0 0 0 1px #ae9bcf;
}

.customSwitch[value='inactive']:disabled+label {
    background: #bfcbd2;
    box-shadow: none;
    cursor: not-allowed;
}

.customSwitch[value='inactive']:disabled+label>i {
    box-shadow: 0 0 0 1px #bfcbd2;
}

/* CUSTOM BUTTON DESIGN  */

.btn {
    display: inline-block;
    /* min-width: 140px; */
    font-size: 14px;
    line-height: 1.29;
    height: 44px;
    padding: 13px 16px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid transparent;
    position: relative;
    transition: box-shadow .3s ease-in-out;
}

.btn:hover {
    box-shadow: 5px 5px 10px rgba(33, 33, 33, .2);

}

.btn.btn-small {
    height: 36px;
    padding: 9px;
    line-height: 16px;
}

.btn.btn-smaller {
    height: 32px;
    padding: 7px;
    line-height: 16px;
}

.btn.btn-large {
    height: 44px;
    padding: 11px;
    line-height: 18px;
}

.btn.btn-stretch {
    min-width: 200px;
    width: 200px;
}

.btn.btn-stretch-2 {
    min-width: 160px;
    width: 160px;
}

.btn.btn-stretch-3 {
    min-width: 184px;
    width: 184px;
}

.btn.btn-shrink {
    min-width: 80px;
    width: 80px;
}

.btn.btn-edit {
    min-width: 88px;
    width: 88px;
    height: 40px;
    margin: 8px 0 8px 16px;
    padding-top: 10px;
}

.btn.btn-shrink-2 {
    min-width: 110px;
    width: 110px;
}

.btn.btn-shrink-3 {
    min-width: 120px;
    width: 120px;
}

.btn-primary {
    background-color: #7850FA;
    border: solid 1px #7850FA;
}

.btn-primary-hollow {
    border: solid 1px #7850FA;
    color: #7850FA;
}

.btn-secondary {
    background-color: #db3186;
    border: solid 1px #db3186;
}

.btn-secondary-hollow {
    border: solid 1px #db3186;
    color: #db3186;
}

.btn-tertiary-hollow {
    color: #00B898;
    border: solid 1px #00B898;
}

.btn-tertiary {
    background-color: #00B898;
    border: solid 1px #00B898;
}

.btn-success {
    background-color: #47c776;
    border: solid 1px #47c776;
}

.btn-success-hollow {
    border: solid 1px #47c776;
    color: #47c776;
}

.btn-delete {
    background-color: #e1454b;
    border: solid 1px #e1454b;
}

.btn-delete-hollow {
    border: solid 1px #e1454b;
    color: #e1454b;
}

.btn-delete-faded-red {
    border: solid 1px #d9575b;
}

.btn-gray {
    background: #7e95a3;
}

.btn-gray-hollow {
    border: solid 1px #7e95a3;
}

.btn-pink-hollow {
    border: solid 1px #eb3b8c;
}

.btn-border-primary {
    border: 1px solid #7850FA;
}

.btn[disabled]:not(.loading-ajax) {
    cursor: not-allowed;
    background-color: #eaeef1;
    border: solid 1px #dfe3e9;
    color: #93aebf;
}

.loading-ajax {
    position: relative;
}

.btn.loading-ajax:after {
    content: url('../img/ajax-button-loader.svg');
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    padding: 7.5px;
    cursor: not-allowed;
    background: initial;
}

.btn-primary.loading-ajax:after {
    background: #7850FA;
}

.btn-secondary.loading-ajax:after {
    background: #db3186;
}

.btn-success.loading-ajax:after {
    background: #47c776;
}

.btn-delete.loading-ajax:after {
    background: #e1454b;
}

.editBtn {
    min-width: 70px;
    top: 16px;
}

/* CUSTOM TIC TAC DESIGN  */

.tic-tac-container {
    display: inline-flex;
}

.tic-tac-field {
    min-width: 96px;
    height: 36px;
    font-size: 13px;
    line-height: 34px;
    padding: 0;
    text-align: center;
    border: 1px solid #dfe3e9;
    border-left: none;
    display: flex;
}

.tic-tac-field:first-child {
    border-left: 1px solid #dfe3e9;
    border-radius: 4px 0 0 4px;
}

.tic-tac-field:last-child {
    border-radius: 0 4px 4px 0;
}

.tic-tac-field.selected {
    border: 1px solid #eb3b8c;
    background: transparent;
    color: #eb3b8c;
}

.tic-tac-field input {
    display: none;
}

.tic-tac-field label {
    margin: 0 auto;
    width: 100%;
    cursor: pointer;
}


.card {
    border: 1px solid #eaebef !important;
    background-color: #ffffff;

    border-radius: 8px !important;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 16px;
}

/*.card:hover{
border: solid 1px #ced2d8;
box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.04);
}*/
/* SNACKBAR */

.snackbar {
    font-family: muli, Helvetica, Lato, sans-serif;
    position: fixed !important;
    z-index: 99999 !important;
    top: auto !important;
    bottom: -4px !important;
    left: 56px !important;
    min-width: 300px !important;
    text-align: left !important;
    border-radius: 4px !important;
    /* transform: translate(-60%, 0) !important; */
    background-color: #323232 !important;
    /* transition: all 0.3s !important; */
    /* padding: 16px 14px 16px 14px !important; */
    line-height: 16px !important;
    font-size: 13px !important;
    font-weight: bold;
    color: rgba(255, 255, 255, .75) !important;
    box-sizing: border-box !important;
    word-wrap: break-word;
    max-width: 260px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12) !important;
}

.snackbar-small {
    position: fixed !important;
    z-index: 99999 !important;
    top: 0 !important;
    bottom: auto !important;
    left: 0% !important;
    width: 100% !important;
    padding: 18px !important;
    line-height: 20px !important;
    font-size: 14px !important;
}

.mat-simple-snackbar-action {
    background-color: transparent;
}

.snackbar>.mat-simple-snackbar {

    line-height: 16px !important;
}

.snackbar>.mat-simple-snackbar>button {
    background: orange !important;
    background-repeat: no-repeat !important;
    width: 16px !important;
    height: 16px !important;
    position: absolute !important;
    top: 10px !important;
    right: 8px;
}

.snackbar.error {
    background-color: #cc4146 !important;
}

.snackbar.success {
    background-color: #258750 !important;
}

.snackbar.alert {
    background-color: #f7981c !important;
}

.snackbar-small.error {
    background-color: #cc4146 !important;
}

.snackbar-small.success {
    background-color: #36af47 !important;
}

.snackbar-small.alert {
    background-color: #f7981c !important;
}

/* MORE OPTIONS CUSTOM */

.more-options-custom {
    display: inline-block;
    position: relative;
}

.more-options-custom .more-options-icon {
    display: inline-block;
    cursor: pointer;
}

.more-options-custom .more-options-icon img {
    cursor: pointer;
}

.more-options-custom .more-options-list {
    position: absolute;
    top: 25px;
    right: -10px;
    background: #FFF;
    border-radius: 2px;
    box-shadow: 0 8px 10px 0 rgba(12, 30, 44, 0.2), 0 6px 30px 5px rgba(12, 30, 44, 0.12), 0 16px 24px 2px rgba(12, 30, 44, 0.14);
    padding: 8px 0;
    z-index: 111;
}

.more-options-custom .more-options-list li {
    height: 36px;
    display: block;
}

.more-options-custom .more-options-list li a {
    padding: 6px 8px;
    height: 36px;
    min-width: 104px;
    display: flex;
    align-items: center;
    background: transparent;
    font-size: 13px;
    font-weight: 600;
    line-height: 24px;
}

.more-options-custom .more-options-list li a:hover {
    background: #dfe3e9;
    text-decoration: none;
}

.more-options-custom .more-options-list li a * {
    display: inline-block;
}

/* CUSTOM STANDARD TABLE */

.custom-standard-table {
    border-radius: 4px;
    border-collapse: collapse;
    box-shadow: 0 2px 12px -2px rgba(0, 0, 0, 0.2);
}

.custom-standard-table,
.custom-standard-table thead,
.custom-standard-table tbody,
.custom-standard-table thead>tr,
.custom-standard-table tbody>tr {
    width: 100%;
}

.custom-standard-table thead>tr {
    background: #ffffff;
}

.custom-standard-table tbody>tr:not(:last-child) {
    border-bottom: 1px solid #eaebef;
}

.custom-standard-table tbody>tr.pos-history-row>td {
    position: relative;
}

.custom-standard-table tbody>tr.pos-history-row>td:last-child {
    padding-right: 86px !important;
}

.custom-standard-table tbody>tr.pos-history-row:not(.pos-history-selected):hover>td:last-child:after {
    content: 'View';
    position: absolute;
    top: 20px;
    right: 26px;
    font-size: 11px;
    line-height: 16px;
    color: #7e95a3;

    font-weight: 600;
    text-transform: uppercase;
}

.custom-standard-table tbody>tr.pos-history-row:not(.pos-history-selected):hover>td:last-child:before {
    content: '';
    display: inline-block;
    position: absolute;
    right: 16px;
    top: 24px;
    height: 6px;
    width: 6px;
    border-left: 1px solid #7e95a3;
    border-bottom: 1px solid #7e95a3;
    transform: rotate(-135deg);

}

.custom-standard-table tbody>tr.pos-history-row:not(.pos-history-selected):hover>td {
    background-color: #f6f7f8 !important;
    box-shadow: inset 0px 1px 0px 0px #7e95a3, inset 0px -1px 0px 0px #7e95a3;
}

.custom-standard-table tbody>tr.pos-history-row:not(.pos-history-selected):hover>td:first-child {
    box-shadow: inset 0px 1px 0px 0px #7e95a3, inset 0px -1px 0px 0px #7e95a3, inset 1px 0px 0px 0px #7e95a3;
}

.custom-standard-table tbody>tr.pos-history-row:not(.pos-history-selected):hover>td:last-child {
    box-shadow: inset 0px 1px 0px 0px #7e95a3, inset 0px -1px 0px 0px #7e95a3, inset -1px 0px 0px 0px #7e95a3;
}

.custom-standard-table tbody>tr.pos-history-selected>td:last-child:after {
    content: 'Selected';
    position: absolute;
    top: 20px;
    right: 16px;
    font-size: 11px;
    line-height: 16px;
    color: #00b898;

    font-weight: 600;
    text-transform: uppercase;
}

.custom-standard-table tbody>tr.pos-history-selected>td {
    background-color: #f3faf9 !important;
    box-shadow: inset 0px 1px 0px 0px #00b898, inset 0px -1px 0px 0px #00b898;
}

.custom-standard-table tbody>tr.pos-history-selected>td:first-child {
    box-shadow: inset 0px 1px 0px 0px #00b898, inset 0px -1px 0px 0px #00b898, inset 1px 0px 0px 0px #00b898;
}

.custom-standard-table tbody>tr.pos-history-selected>td:last-child {
    box-shadow: inset 0px 1px 0px 0px #00b898, inset 0px -1px 0px 0px #00b898, inset -1px 0px 0px 0px #00b898;
    position: relative;
}

.custom-standard-table thead>tr>th {
    white-space: nowrap;
    padding: 16px !important;
    line-height: 16px;
    font-size: 13px;
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;

    color: #7e95a3;
    background: #fff;
}

.custom-standard-table thead>tr>th.sortable-column:hover {
    cursor: pointer;
}

.custom-standard-table thead>tr>th.sortable-column {
    position: relative;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.custom-standard-table thead>tr>th.sortable-column.active:after {
    position: relative;
    content: '';
    height: 0;
    width: 0;
    top: -2px;
    right: -5px;
    border-top: 0;
    border-bottom: 5px solid #7e95a3;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    transition: all 0.3s;
}

.custom-standard-table thead>tr>th.sortable-column.active.asc:after {
    display: inline-block;
    transform: rotate(0deg);
}

.custom-standard-table thead>tr>th.sortable-column.active.desc:after {
    display: inline-block;
    transform: rotate(180deg);
}

.custom-standard-table thead>tr>th.sortable-column.active.none:after {
    display: none;
}

.custom-standard-table thead>tr>th.center,
.custom-standard-table tbody>tr>td.center {
    text-align: center;
}

.custom-standard-table thead>tr>th.right,
.custom-standard-table tbody>tr>td.right {
    text-align: right;
}

.custom-standard-table tbody>tr>td {
    padding: 18px 16px;
    line-height: 20px;
    font-size: 13px;
    color: #0d1f2d;
    background: #fff;
}

.custom-standard-table tbody>tr>td .link {
    font-weight: 600;
    color: #0072a3;
    text-transform: capitalize;
}

.custom-standard-table tbody>tr>td .link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.custom-standard-table tbody>tr .fade {
    color: #7e95a3;
}

.custom-standard-table.alternate-1 tbody>tr:nth-child(even)>td {
    background: #f3f3fa;
}

.custom-standard-table.alternate-1 tbody>tr:nth-child(odd)>td {
    background: #fff;
}

.ng2-smart-filters {
    display: none !important;
}

.custom-inline-table {
    border-radius: 3px;
    background-color: #ffffff;
    border-collapse: collapse;
    box-shadow: 0 1px 3px 0 rgba(12, 30, 44, 0.2), 0 2px 2px 0 rgba(12, 30, 44, 0.12), 0 0 2px 0 rgba(12, 30, 44, 0.14);
}

.custom-inline-table,
.custom-inline-table thead,
.custom-inline-table tbody,
.custom-inline-table thead>tr,
.custom-inline-table tbody>tr {
    width: 100%;
}

.custom-inline-table {
    position: relative;
}

.custom-inline-table:after {
    content: '';
    height: 1px;
    width: calc(100% - 32px);
    position: absolute;
    left: 16px;
    top: 39px;
    background: #f3f5f8;
}

.custom-inline-table thead>tr>th {
    white-space: nowrap;
    padding: 12px 16px;
    line-height: 16px;
    font-size: 13px;
    text-align: left;
    text-transform: uppercase;

    font-weight: 600;
    color: #7e95a3;
}

.custom-inline-table thead .header {
    font-weight: 600;

    color: #7f6ca6;
}

.custom-inline-table .right {
    text-align: right;
}

.custom-inline-table .center {
    text-align: center;
}

.custom-inline-table tbody>tr>td {
    font-size: 14px;
    color: #0d1f2d;
    line-height: 16px;
    padding: 8px 16px;
}

.custom-inline-table tbody>tr:first-child>td {
    padding-top: 16px;
}

.custom-inline-table tbody>tr:last-child>td {
    padding-bottom: 16px;
}

.custom-inline-table tbody>tr>td.double {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.custom-inline-table tbody .fade {
    color: #7e95a3;
}

.custom-inline-table tbody .link {
    font-weight: 600;
    text-align: left;
    color: #0072a3;
    cursor: pointer;
}

.custom-inline-table tbody .link:hover {
    text-decoration: underline;
}

.custom-inline-table tbody .link-gray {
    font-weight: 600;

    text-decoration: underline;
    color: #546a7b;
    cursor: pointer;
}

/* CUSTOM FORM DESIGN */

.custom-form {
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.04);
    border-left: solid 1px #dfe3e9;
    border-right: solid 1px #dfe3e9;
    border-bottom: solid 1px #dfe3e9;
}

.custom-form h4 {
    background-color: #f5f5fa;
    border-top: solid 1px #dfe3e9;
    border-bottom: solid 1px #dfe3e9;
    color: #8a7ea3;
}

.custom-form>h4 {
    display: block;
    padding: 12px 32px;
    margin: 0;
    margin-bottom: 16px;
}

.custom-form>h4:not(:first-child) {
    margin-top: 16px;
}

.input-field {
    position: relative;
    display: block;
}

.input-field>input[type='text'],
.input-field>input[type='number'],
.input-field>input[type='password'],
.input-field>input[type='email'],
.input-field>input[type='tel'],
.input-field>textarea {
    font-size: 14px;
    line-height: 20px;
    padding: 9px;
    display: inline-block;
    width: 50%;
    border-radius: 3px;
    margin: 13px 0;
    border: solid 1px #dfe3e9;
}

.input-field>.field-state {
    display: inline-block;
    width: 48% !important;
    position: relative;
    font-size: 12px;
    line-height: 20px;
    padding: 8px;
}

.input-field>.field-state:after {
    content: "";
    font-size: 12px;
    line-height: 20px;
    position: absolute;
    top: 5px;
    left: 10px;
    width: 200%;
    opacity: 0;
}

.input-field input[type='text']:hover,
.input-field>input[type='number']:hover,
.input-field input[type='password']:hover,
.input-field input[type='email']:hover,
.input-field input[type='tel']:hover,
.input-field textarea:hover {
    border: solid 1px #ced0da;
}

.input-field input[type='text']+label,
.input-field>input[type='number']+label,
.input-field input[type='password']+label,
.input-field input[type='email']+label,
.input-field input[type='tel']+label,
.input-field textarea+label {
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    top: 23px;
    left: 8px;
    padding: 0;
    text-align: left;
    color: #7e95a3;
    background: transparent;
    cursor: text;
    transition: all 0.3s;
}

.input-field input[type='text']:focus,
.input-field>input[type='number']:focus,
.input-field input[type='password']:focus,
.input-field input[type='email']:focus,
.input-field input[type='tel']:focus,
.input-field textarea:focus {
    border: solid 1px #7850FA;
}

.input-field input[type='text'].active+label,
.input-field>input[type='number'].active+label,
.input-field input[type='email'].active+label,
.input-field input[type='password'].active+label,
.input-field input[type='tel'].active+label,
.input-field textarea.active+label,
.input-field input[type='text']:focus+label,
.input-field>input[type='number']:focus+label,
.input-field input[type='email']:focus+label,
.input-field input[type='password']:focus+label,
.input-field input[type='tel']:focus+label,
.input-field textarea:focus+label {
    font-size: 11px;
    background: white;
    transform: translate(-3px, -18px);
    padding: 0 3px;
}

.input-field input[type='text']:focus+label,
.input-field>input[type='number']:focus+label,
.input-field input[type='email']:focus+label,
.input-field input[type='password']:focus+label,
.input-field input[type='tel']:focus+label,
.input-field textarea:focus+label {
    color: #7850FA;
}

.input-field input[type='text']:focus~.field-state:after,
.input-field>input[type='number']:focus~.field-state::after,
.input-field input[type='password']:focus~.field-state:after,
.input-field input[type='email']:focus~.field-state:after,
.input-field input[type='tel']:focus~.field-state:after,
.input-field textarea:focus~.field-state:after,
.input-field input[type='text']~.field-state:after,
.input-field>input[type='number']~.field-state::after,
.input-field input[type='password']~.field-state:after,
.input-field input[type='email']~.field-state:after,
.input-field input[type='tel']~.field-state:after,
.input-field textarea~.field-state:after {
    /*content: url('../img/info-icon.svg') attr(data-info);*/

    content: attr(data-info);
    color: #546a7b;
    opacity: 1;
}

.input-field input[type='text'].invalid:not(:focus),
.input-field>input[type='number'].invalid:not(:focus),
.input-field input[type='password'].invalid:not(:focus),
.input-field input[type='email'].invalid:not(:focus),
.input-field input[type='tel'].invalid:not(:focus),
.input-field textarea.invalid:not(:focus),
.submit-false .input-field input[type='text'].ng-invalid:not(:focus),
.submit-false .input-field>input[type='number'].ng-invalid:not(:focus),
.submit-false .input-field input[type='password'].ng-invalid:not(:focus),
.submit-false .input-field input[type='email'].ng-invalid:not(:focus),
.submit-false .input-field input[type='tel'].ng-invalid:not(:focus),
.submit-false .input-field textarea.ng-invalid:not(:focus) {
    border: solid 1px #EF767A;
}

.input-field input[type='text'].invalid:not(:focus)+label,
.input-field>input[type='number'].invalid:not(:focus)+label,
.input-field input[type='password'].invalid:not(:focus)+label,
.input-field input[type='email'].invalid:not(:focus)+label,
.input-field input[type='tel'].invalid:not(:focus)+label,
.input-field textarea.invalid:not(:focus)+label,
.submit-false .input-field input[type='text'].invalid:not(:focus)+label,
.submit-false .input-field>input[type='number'].invalid:not(:focus)+label,
.submit-false .input-field input[type='password'].invalid:not(:focus)+label,
.submit-false .input-field input[type='email'].invalid:not(:focus)+label,
.submit-false .input-field input[type='tel'].invalid:not(:focus)+label,
.submit-false .input-field textarea.invalid:not(:focus)+label {
    color: #EF767A;
}

.input-field input[type='text'].invalid:not(:focus)~.field-state:after,
.input-field>input[type='number'].invalid:not(:focus)~.field-state::after,
.input-field input[type='password'].invalid:not(:focus)~.field-state:after,
.input-field input[type='email'].invalid:not(:focus)~.field-state:after,
.input-field input[type='tel'].invalid:not(:focus)~.field-state:after,
.input-field textarea.invalid:not(:focus)~.field-state:after {
    /*content:  url('../img/error-icon.svg') attr(data-error);*/

    content: attr(data-error);
    color: #EF767A;
    opacity: 1;
}

.input-field input[type='text'].valid:not(:focus),
.input-field>input[type='number'].valid:not(:focus),
.input-field input[type='password'].valid:not(:focus),
.input-field input[type='email'].valid:not(:focus),
.input-field input[type='tel'].valid:not(:focus),
.input-field textarea.valid:not(:focus) {
    border: solid 1px #dfe3e9;
}

.input-field input[type='text'].valid:not(:focus)+label,
.input-field>input[type='number'].valid:not(:focus)+label,
.input-field input[type='password'].valid:not(:focus)+label,
.input-field input[type='email'].valid:not(:focus)+label,
.input-field input[type='tel'].valid:not(:focus)+label,
.input-field textarea.valid:not(:focus)+label {
    color: #7e95a3;
}

.input-field input[type='text']:not(:focus).valid~.field-state:after,
.input-field>input[type='number']:not(:focus).valid~.field-state::after,
.input-field input[type='password']:not(:focus).valid~.field-state:after,
.input-field input[type='email']:not(:focus).valid~.field-state:after,
.input-field input[type='tel']:not(:focus).valid~.field-state:after,
.input-field textarea:not(:focus).valid~div:after {
    /*content: url('../img/success-icon.svg')  attr(data-success);*/

    content: attr(data-success);
    color: #5FDD9D;
    opacity: 1;
}

.input-field input[type='text'][disabled],
.input-field>input[type='number'][disabled],
.input-field input[type='password'][disabled],
.input-field input[type='email'][disabled],
.input-field input[type='tel'][disabled],
.input-field textarea[disabled] {
    border: solid 1px #dfe3e9 !important;
    background: #eaeef1;
    color: #7e95a3;
}

.input-field input[type='text'][disabled]+label,
.input-field>input[type='number'][disabled]+label,
.input-field input[type='password'][disabled]+label,
.input-field input[type='email'][disabled]+label,
.input-field input[type='tel'][disabled]+label,
.input-field textarea[disabled]+label {
    display: none;
}

/*.input-field input[type='text'][disabled]+label.active,
.input-field input[type='password'][disabled]+label.active,
.input-field input[type='email'][disabled]+label.active,
.input-field input[type='tel'][disabled]+label.active,
.input-field textarea[disabled]+label.active{
background: linear-gradient(to bottom, transparent 6px, #eaeef1);
}*/
/* GENERAL MD2 DATEPICKERS STYLE OVERRIDE */

.md2-datepicker-trigger {
    padding: 15px 0 !important;
    height: 64px !important;
    position: relative !important;
}

.md2-datepicker-button {
    position: absolute !important;
    top: 14px !important;
    right: 1px !important;
    left: auto !important;
    height: 32px !important;
    width: 32px !important;
    padding: 5px !important;
    z-index: 1 !important;
}

.md2-datepicker-button svg {
    fill: #546a7b;
}

.md2-datepicker-input {
    height: 34px !important;
    border: 1px solid #dfe3e9 !important;
    padding-right: 32px !important;
    border-radius: 3px;
}

[aria-disabled=true] .md2-datepicker-input {
    background: #eaeef1;
}

[aria-disabled=true] .md2-datepicker-input .md2-datepicker-placeholder {
    display: none;
}

.md2-datepicker-input:hover {
    border: 1px solid #ced0da;
}

.md2-datepicker-input.md2-datepicker-input-focused {
    border: 1px solid #7850FA;
    border-color: #7850FA !important;
}

.md2-datepicker-input .md2-datepicker-arrow {
    display: none;
}

.md2-datepicker-input input {
    font-size: 13px;
    line-height: 16px;
    top: 0px;
    padding-left: 8px;
}

.md2-datepicker-input .md2-datepicker-placeholder {
    font-size: 14px;
    line-height: 32px;
    padding: 0 3px !important;
    top: 0;
    bottom: 0 !important;
    left: 5px !important;
    color: #7e95a3;
    right: auto !important;
    background: #fff;
    transform: none !important;
}

.md2-datepicker-input .md2-datepicker-placeholder.md2-floating-placeholder {
    font-size: 14px;
    line-height: 20px;
    left: 5px !important;
    right: auto !important;
    padding: 0 3px;
    transform: translate(0, -8px) scale(1.0) !important;
    color: #7e95a3;
    height: 16px;
    width: auto;
}

.md2-datepicker-input.md2-datepicker-input-focused .md2-datepicker-placeholder.md2-floating-placeholder {
    color: #7850FA;
}

/* MODAL CSS */

.modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.38);
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background-color: #ffffff;
    z-index: 1001;
    width: 448px;
    height: 240px;
    border-radius: 10px;
    padding: 16px 8px;
}

.bigger-modal-content {
    height: auto !important;
}

.bigger-modal-content .modal-header>p,
.bigger-modal-content .modal-header>p>span {
    display: block !important;
    text-align: left !important;
    padding: 0 !important;
}

.bigger-modal-content .modal-body {
    height: auto !important;
}

.modal-actions {
    display: flex;
}

.modal-actions>a {
    margin: 0 8px;
    flex-grow: 6;
}

.modal-actions>button {
    margin: 0 8px;
    flex-grow: 6;
}

.modal-header {
    margin: 2px 8px;
    text-align: center;
}

.modal-header>p {
    display: inline-block;
}

.modal-header>p>span {
    line-height: 32px;
    font-size: 18px;
    font-weight: 600;
    color: #546a7b;
    padding-left: 4px;
}

.modal-body {
    height: 132px;
    padding: 0 8px;
    text-align: center;
}

.text-logo {
    color: #7850FA;
}

/* Membership plan form styles */

.absolutePosition {
    position: absolute !important;
}

.right40 {
    right: 40px;
}

.right0 {
    right: 0px
}

.bottomInherit {
    bottom: inherit !important
}

.left-arrow:after {
    content: '';
    height: 7px;
    width: 7px;
    display: block;
    border-right: 2px solid #7e95a3;
    border-top: 2px solid #7e95a3;
    position: relative;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    -webkit-transition: all 0s;
    transition: all 0s;
}

.right-arrow:after {
    content: '';
    height: 7px;
    width: 7px;
    display: block;
    border-left: 2px solid #7e95a3;
    border-bottom: 2px solid #7e95a3;
    position: relative;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    -webkit-transition: all 0s;
    transition: all 0s;
}

.input-select:after {
    /* here you should align your dropdown item by providing respective top and left  */
    content: '';
    height: 7px;
    width: 7px;
    display: block;
    border-left: 2px solid #7e95a3;
    border-top: 2px solid #7e95a3;
    position: relative;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    -webkit-transition: all 0s;
    transition: all 0s;
}

.upArrow:after {
    content: '';
    height: 7px;
    width: 7px;
    display: block;
    border-right: 2px solid #7e95a3;
    border-bottom: 2px solid #7e95a3;
    position: relative;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    -webkit-transition: all 0s;
    transition: all 0s;
}

.hideSettings:after {
    top: -10px;
    left: 158px;
}

.dateSelectPosition:after {
    top: -36px;
    left: 60px;
}

.typeSelectPosition:after {
    top: -36px;
    right: -90px;
}

.advancedSettings:after {
    top: -14px;
    left: 158px;
}

.fullWidth {
    width: 100% !important;
}

.planSelection {
    padding: 11px 24px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    width: 34%;
    border-radius: 3px;
    margin: 32px 0 12px 0;

    border: solid 1px #dfe3e9;
    color: #546a7b;
    font-weight: bold;
}

.planSelection.active {
    border: 1px solid #7850FA;
    color: #7850FA;
}

.planSelectionIcon {
    display: inline-block;
    position: absolute;
    margin-top: 42px;
    margin-left: 30px;
}

.incrementDecrement {
    display: inline-block;
    width: 128px;
    height: 40px;
}

.boxBorder {
    border: 1px solid #dfe3e9;
}

.borderNone {
    border-right: inherit;
    border-left: inherit;
}

.vertical-text-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pointerHover:hover {
    cursor: pointer;
}


.backBtnHeader {
    position: relative;
    top: 2px;
    font-family: Lato;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #546a7b;
}

.calc-margin-left10 {
    margin-bottom: 30px;
    margin-right: -30px;
}

.calc-back-margin-left70 {
    margin-left: calc(100vh - 270px);
    /* top: 45px; */
}

.calc-back-margin-left20 {
    margin-left: calc(100vh - 320px);
    top: 45px;
}

.calc-margin-left60 {
    margin-left: calc(100vh - 160px);
}

.downArrow-select {
    content: '';
    height: 7px;
    width: 7px;
    display: block;
    border-left: 2px solid rgba(255, 255, 255, 0.35);
    border-bottom: 2px solid rgba(255, 255, 255, 0.35);
    position: absolute;
    top: 288px;
    left: 155px;
    transform: rotate(-45deg);
    transition: all 0s;
}

.input-recurring-cycle {
    display: inline-block;
    border: 1px solid #dfe3e9;
    border-radius: 3px;
    width: 48px;
    height: 40px;
    padding: 20px 11px;
    margin-top: 12px;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #0d1f2d;
}

.switch-label {
    position: absolute;
    margin-top: 22px;
}

.btn-disable:hover,
.btn-disable:focus,
.btn-disable:active {
    text-decoration: none;
    cursor: not-allowed;
}