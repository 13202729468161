.policy-modal-segment{
    border-bottom:1px solid #dfe3e9;
    &#no-border{
        border-bottom:0px;
    }
    padding:24px 24px 24px 24px;
    .segment-content{
        .segment-header{
            font-size: 14px;
            font-weight: bold;
            color: #0d1f2d;
        }
        .segment-desc{
            font-size: 14px;
            color: #0d1f2d;
            margin-top:8px;
        }
        .set-flex{
            input{
                padding:8px;
            }
        }
    }
}
